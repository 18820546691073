import React from 'react'
import styled, { css } from 'styled-components'
import { IRemovableItem } from './IRemovableItem'

const Wrapper = styled.div`
  display: flex;
`
const Item = styled.li(({ theme }) => {
  const { palette } = theme
  return css`
    cursor: pointer;
    display: flex;
    padding: 9px;
    color: ${palette.white};
    background: rgba(61, 68, 81, 0.5);
    border-radius: 5px;
    line-height: 17px;
    font-size: 14px;
  `
})

const RemoveButton = styled.button(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${palette.violetLight};
    border: none;
    position: relative;
    right: 8px;
    bottom: 8px;
    cursor: pointer;

    > span {
      background-color: ${palette.white};
      width: 8.6px;
      height: 2px;
      border-radius: 100px;
    }
  `
})

export const RemovableItem = ({ label, handleRemoveItem, id, handleClick }: IRemovableItem) => {
  const handleClickAction = () => {
    if (handleClick) handleClick(id)
  }
  return (
    <Wrapper>
      <Item onClick={handleClickAction}>{label}</Item>
      <RemoveButton onClick={() => handleRemoveItem(id)}>
        <span />
      </RemoveButton>
    </Wrapper>
  )
}
