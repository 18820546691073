import { getAxios, to } from '../../utils/axios'
import { config } from '../../utils/config'
import { ENDPOINTS } from '../../utils/endpoints'

export type IResetPasswordBody = {
  code: string
  password: string
  passwordConfirmation: string
}

async function resetPassword(body: IResetPasswordBody) {
  const request = getAxios(config.API_URL, true).post(ENDPOINTS.RESET_PASSWORD, body)

  const [error, response] = await to(request)

  if (error) {
    return [error, null]
  }
  return [null, response?.data]
}

export default resetPassword
