/* eslint-disable no-unused-vars */
import { createPassword } from '../../functions/createPassword'
import createNewUser from '../signUp/createNewUser'
import getUserByEmail from './getUserByEmail'

export const checkAndCreateUser = async (email: string) => {
  // checks if there is already such a user
  const [errorAdminByEmail, responseUserByEmail] = await getUserByEmail(email)
  if (responseUserByEmail[0]?.id) {
    return responseUserByEmail[0]?.id
  } else {
    // creates new user
    // todo - sends invitation email
    const [errorCreateUser, responseCreateUser] = await createNewUser({
      email: email,
      password: createPassword(),
      userType: 'admin',
      privacy: false,
      isAutoCreated: true
    })

    if (responseCreateUser?.user?.id) {
      return responseCreateUser?.user?.id
    }
    // check user one more time in case it was created in another department/tag in the same time
    if (errorCreateUser) {
      const [errorAdminByEmail, responseUserByEmail] = await getUserByEmail(email)
      if (responseUserByEmail[0]?.id) {
        return responseUserByEmail[0]?.id
      }
    }
  }
}
