export const ENDPOINTS = {
  // text content
  MAIN_PAGE_CONTENT:
    '/api/main-page?populate[firstDescImage][populate]=*&populate[secondDescImage][populate]=*&populate[firstDescImageMobile][populate]=*&populate[subscribeCard][populate]=*&populate[howItWorksImage][populate]=*&populate[creatorImage][populate]=*&populate[creatorImageMobile][populate]:*&populate[creatorLogo][populate]=*&populate[howItWorksImageMobile][populate]=*',
  SIGN_UP_CONTENT: '/api/sing-up?populate[SocialLogins][populate][Social][populate]=*',
  SIGN_IN_CONTENT:
    '/api/sign-in?populate[socialLogins][populate][Social][populate]=*&populate[signInImage]=*&populate[signInImageMobile]=*',
  FORGOT_PASSWORD_CONTENT: '/api/forgot-password?populate[forgotImage]=*',
  RESET_PASSWORD_CONTENT: '/api/reset-password?populate[resetImage]=*&populate[resetImageMobile]=*',
  REGISTER_COMPANY_CONTENT:
    '/api/register-company-page?populate[registerCompanyBox]=*&populate[colorThemeBox]=*&populate[departmentsBox]=*',
  SETTINGS_CONTENT:
    '/api/settings-page?populate[companyBox]=*&populate[colorThemeBox]=*&populate[tagsBox]=*&populate[departmentsBox]=*&populate[headerImage]=*',
  FOOTER: '/api/footer',
  COOKIES: '/api/cookie?populate[cookiesSettings]=*',
  CONVERSATIONS_PAGE:
    '/api/conversations-page?populate[messageStateTag]=*&populate[importanceTags]=*',

  // api calls
  USER_REGISTER: '/api/auth/local/register',
  AUTH_USER: '/api/auth/local',
  FORGOT_PASSWORD: '/api/auth/forgot-password',
  RESET_PASSWORD: '/api/auth/reset-password',
  USERS: '/api/users',
  USER_ME:
    '/api/users/me?populate[companies][populate][users_permissions_users]=*&populate[companies][populate][departments][populate]=*&populate[companies][populate][tags][populate]=*&populate[companies][populate][conversations][populate]=*',
  FACEBOOK_LOGIN: '/api/auth/facebook/callback?access_token=',
  COMPANY: '/api/companies',
  DEPARTMENT: '/api/departments',
  TAG: '/api/tags',
  CONVERSATIONS: '/api/conversations',
  MESSAGE: '/api/messages'
}
