import styled from 'styled-components'
import { ReactComponent as SuggestionBoxIcon } from '../../../assets/icons/box.svg'

export const PositionedSuggestionBoxIcon = styled(SuggestionBoxIcon)`
  margin: 25px 0 0 0;
  position: fixed;

  // smaller screen
  @media screen and (min-width: 1080px) and (max-width: 1350px) {
    margin: 25px 0 0 0;
  }
  //tablet landscape
  @media screen and (min-width: 950px) and (max-width: 1080px) {
    position: absolute;
  }
  //mobile / tablet horizontal
  @media screen and (max-width: 950px) {
    position: absolute;
  }
`
