import { createSlice } from '@reduxjs/toolkit'
import getSignUpContent from '../../api/textContent/getSignUpContent'
import { AppThunk } from '../store'
import { ISignUp } from '../../components/organisms/SignUp/ISignUp'
import createNewUser, { INewUserBody } from '../../api/signUp/createNewUser'
import { IUser } from '../../types/IUser'
import getUser from '../../api/signIn/getUser'
import getSignInContent from '../../api/textContent/getSignInContent'
import { ISignIn } from '../../components/organisms'
import authUser, { IAuthUserBody } from '../../api/signIn/authUser'
import getForgotPasswordContent from '../../api/textContent/getForgotPasswordContent'
import { IForgotPassword } from '../../components/pages/ForgotPasswordPage/IForgotPassword'
import { IResetPassword } from '../../components/pages/ResetPasswordPage/IResetPassword'
import getResetPasswordContent from '../../api/textContent/getResetPasswordContent'
import resetPassword, { IResetPasswordBody } from '../../api/signIn/resetPassword'
import facebookLogin, { IFacebookLoginBody } from '../../api/facebookLogin/facebookLogin'
import { saveCompanyData, saveUsersConversations, saveUsersDepartments } from './accountSlice'

export interface SignInState {
  signUpData?: {
    data?: ISignUp
    error?: string
  }
  signInData?: {
    data?: ISignIn
    error?: string
  }
  user?: {
    data?: IUser
    error?: string
  }

  forgotPasswordData?: {
    data?: IForgotPassword
    error?: string
  }
  resetPasswordData?: {
    data?: IResetPassword
    error?: string
  }
}

const initialState: SignInState = {}

export const signInSlice = createSlice({
  name: 'signIn',
  initialState,
  reducers: {
    saveSignUpData: (state, action) => {
      state.signUpData = action.payload
    },
    saveSignInData: (state, action) => {
      state.signInData = action.payload
    },
    saveUserData: (state, action) => {
      state.user = action.payload
    },

    saveForgotPasswordData: (state, action) => {
      state.forgotPasswordData = action.payload
    },
    saveResetPasswordData: (state, action) => {
      state.resetPasswordData = action.payload
    }
  }
})

const handleSaveUserData =
  (userData: IUser): AppThunk =>
  async (dispatch): Promise<void> => {
    // users company
    const companyData = userData?.companies
    if (companyData?.length) {
      dispatch(saveCompanyData({ data: companyData[0], error: '' }))

      const userId = userData?.id

      // users departments
      const userDepartments = companyData[0]?.departments?.filter(
        (item) =>
          item?.admin?.id === userId ||
          item?.receivers?.map((receiver) => receiver?.id)?.includes(userId)
      )
      dispatch(saveUsersDepartments(userDepartments))

      // users conversations
      const usersConversations = companyData[0]?.conversations?.filter((item) =>
        userDepartments?.map((depart) => depart?.id)?.includes(item?.department?.id)
      )
      dispatch(saveUsersConversations(usersConversations))
    }
  }

export const fetchSignUpData =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const [error, response] = await getSignUpContent()
    dispatch(saveSignUpData({ data: response?.data?.attributes, error: error }))
  }

export const fetchSignInData =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const [error, response] = await getSignInContent()
    dispatch(saveSignInData({ data: response?.data?.attributes, error: error }))
  }

export const fetchForgotPasswordData =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const [error, response] = await getForgotPasswordContent()
    dispatch(saveForgotPasswordData({ data: response?.data?.attributes, error: error }))
  }

export const fetchResetPasswordData =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const [error, response] = await getResetPasswordContent()
    dispatch(saveResetPasswordData({ data: response?.data?.attributes, error: error }))
  }

export const createUser =
  ({ email, password, userType, privacy }: INewUserBody): AppThunk =>
  async (dispatch): Promise<void> => {
    const [error, response] = await createNewUser({
      email,
      password,
      userType,
      privacy
    })

    if (response?.status === 'Authenticated') {
      document.cookie = 'loggedIn=true;Path=/;'
      window?.dispatchEvent(new CustomEvent('loggedIn'))
    }

    dispatch(saveUserData({ data: response?.user, error: error }))
    if (response?.user) {
      dispatch(handleSaveUserData(response?.user))
    }
  }

export const fetchUserData =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const [error, response] = await getUser()

    dispatch(saveUserData({ data: response, error: error }))
    if (response) {
      dispatch(handleSaveUserData(response))
    }
  }

export const signOut =
  (removeCookie: () => void): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(saveUserData({ data: null, error: 'sign-out' }))
    dispatch(saveCompanyData(undefined))
    dispatch(saveUsersDepartments(undefined))
    dispatch(saveUsersConversations(undefined))
    dispatch(saveUsersConversations(undefined))
    removeCookie()
  }

export const signInUser =
  ({ identifier, password }: IAuthUserBody): AppThunk =>
  async (dispatch): Promise<void> => {
    const [error, response] = await authUser({
      identifier,
      password
    })

    if (response?.status === 'Authenticated') {
      document.cookie = 'loggedIn=true;Path=/;'
      window?.dispatchEvent(new CustomEvent('loggedIn'))
      dispatch(fetchUserData())
    } else {
      dispatch(saveUserData({ data: response?.user, error: error }))
    }
  }

export const resetPasswordAction =
  (body: IResetPasswordBody): AppThunk =>
  async (dispatch): Promise<void> => {
    const [error, response] = await resetPassword(body)

    if (response?.status === 'Authenticated') {
      document.cookie = 'loggedIn=true'
      window?.dispatchEvent(new CustomEvent('loggedIn'))
    }
    dispatch(saveUserData({ data: response?.user, error: error }))
    if (response?.user) {
      dispatch(fetchUserData())
    }
  }

export const signInFacebook =
  ({ accessToken }: IFacebookLoginBody): AppThunk =>
  async (dispatch): Promise<void> => {
    const [error, response] = await facebookLogin({ accessToken })

    if (response?.status === 'Authenticated') {
      document.cookie = 'loggedIn=true;Path=/;'
      window?.dispatchEvent(new CustomEvent('loggedIn'))
      dispatch(fetchUserData())
    } else {
      dispatch(saveUserData({ data: response?.user, error: error }))
      if (response?.user) {
        dispatch(handleSaveUserData(response?.user))
      }
    }
  }

// Action creators are generated for each case reducer function
export const {
  saveSignUpData,
  saveUserData,
  saveSignInData,
  saveForgotPasswordData,
  saveResetPasswordData
} = signInSlice.actions

export default signInSlice.reducer
