export const theme = {
  palette: {
    yellow: '#FBB13C', // 400
    violet: '#460DC8', // 600
    violetLight: '#570DF8', // 500
    text: '#3D4451',
    white: '#FFFFFF',
    black: '#000000',
    waring: '#FBBD23', //yellow waring
    error: '#F93E3E',
    disabled: '#D0D0D0'
  },
  shadow: '0px 4px 28px rgba(61, 68, 81, 0.1)',
  typography: {
    primary: {
      fontFamily: `'Exo 2', sans-serif`,
      fontSize: '16px',
      lineHeight: '26px'
    }
  }
}

export type ThemeType = typeof theme
