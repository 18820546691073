import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { ITextInputCopy } from './ITextInputCopy'
import copyIcon from '../../../assets/icons/copy.svg'
import { TextInput } from '../TextInput/TextInput'

type IconProps = {
  onClick?: () => void
}

const Icon = styled.img<IconProps>`
  width: 1.5rem;
  position: absolute;
  right: 10px;
  top: 20%;
  cursor: pointer;
`

export const TextInputCopy: React.FC<ITextInputCopy> = ({
  name,
  onBlur,
  onChange,
  value,
  error,
  placeholder,
  label,
  copyValue,
  secondary
}) => {
  const input = useRef() as MutableRefObject<HTMLInputElement>
  const [customError, setCustomError] = useState<string | undefined>()

  const copyLink = () => {
    if (error) {
      setCustomError(error)
    } else {
      navigator.clipboard.writeText(copyValue)
    }
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur(event)
  }

  useEffect(() => {
    if (input.current) {
      input.current.blur()
    }
  }, [input])

  useEffect(() => {
    if (!error) {
      setCustomError(undefined)
    }
  }, [error])

  return (
    <TextInput
      secondary={secondary}
      disabled
      error={customError}
      label={label}
      name={name}
      onBlur={handleBlur}
      onChange={onChange}
      placeholder={placeholder}
      value={value}>
      <Icon alt="copy" onClick={copyLink} src={copyIcon} />
    </TextInput>
  )
}
