import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { PATHS } from '../../../constants/paths'
import { RootState } from '../../../redux/store'
import { useAppDispatch, useAppSelector } from '../../../redux/storeHooks'
import { Button } from '../../atoms'
import { fetchFooterData } from '../../../redux/slices/mainPageSlice'

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 132px 0 65px;
    font-weight: 400;
    font-size: 21px;
    line-height: 26px;

    > .copyright {
      color: ${palette.black};
      margin-top: 97px;
      font-weight: 400;
      font-size: 21px;
      line-height: 26px;
      text-align: center;
    }

    // smaller screen
    @media screen and (min-width: 1080px) and (max-width: 1350px) {
      padding: 132px 0 65px;
    }
    //tablet landscape
    @media screen and (min-width: 950px) and (max-width: 1080px) {
      padding: 132px 0 65px;
    }

    //mobile / tablet horizontal
    @media screen and (max-width: 950px) {
      padding: 94px 0 65px;
      > .copyright {
        font-size: 18px;
      }
    }
  `
})

const TopWrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    justify-content: space-between;

    > .links-wrapper {
      display: grid;
      grid-template-columns: auto auto auto;
      grid-column-gap: 100px;
      grid-row-gap: 17px;
      > a {
        text-decoration: none;
        color: ${palette.black};

        :visited {
          color: ${palette.violetLight};
        }
      }
    }

    // smaller screen
    @media screen and (min-width: 1080px) and (max-width: 1350px) {
      > button {
        width: 300px;
      }
      > .links-wrapper {
        grid-column-gap: 80px;
      }
    }
    //tablet landscape
    @media screen and (min-width: 950px) and (max-width: 1080px) {
      > a {
        > button {
          width: 250px;
        }
      }
      > .links-wrapper {
        grid-column-gap: 40px;
      }
    }

    //mobile / tablet horizontal
    @media screen and (max-width: 950px) {
      flex-direction: column;
      align-items: center;
      > .links-wrapper {
        grid-template-columns: auto;
        grid-row-gap: 21px;
        > a {
          text-align: center;
        }
      }
      > .register-button {
        margin-top: 56px;
      }
    }
  `
})

export const Footer = () => {
  const dispatch = useAppDispatch()
  const footerData = useAppSelector((state: RootState) => state.mainPage.footerData?.data)
  // fetching text data
  useEffect(() => {
    if (!footerData) {
      dispatch(fetchFooterData())
    }
  }, [])

  return (
    <Wrapper>
      <TopWrapper>
        <div className="links-wrapper">
          <a href="#sign-up">{footerData?.signUp}</a>
          <a href={footerData?.policyLink}>{footerData?.policy}</a>
          <a href={footerData?.contactLink}>{footerData?.contact}</a>
          <a href={`${PATHS.LOGIN}`}>{footerData?.logIn}</a>
          <a href="#how-it-works">{footerData?.howItWorks}</a>
        </div>
        <a href="#sign-up" className="register-button">
          <Button width="300px" height="60px">
            {footerData?.textButton}
          </Button>
        </a>
      </TopWrapper>
      <p className="copyright">{footerData?.copyright}</p>
    </Wrapper>
  )
}
