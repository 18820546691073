export const PATHS = {
  MAIN_PAGE: '',
  LOGIN: '/log-in',
  ACCOUNT: '/account',
  CONVERSATIONS: '/conversations',
  MESSAGE: '/message',
  SETTINGS: '/settings',
  REGISTER_COMPANY: '/register-company',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  PROVIDER_FACEBOOK: '/provider/facebook/redirect'
}
