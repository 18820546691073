/* eslint-disable no-undef */
import axios, { AxiosInstance } from 'axios'
import camelcaseKeys from 'camelcase-keys'

export const to = async (promise: Promise<any>) =>
  promise.then((data) => [null, data]).catch((error) => [error, null])

export const getAxios = (baseUrl?: string, withCredentials?: boolean): AxiosInstance => {
  const testMode = process.env.REACT_APP_ENV !== 'PROD'
  const axiosInstance = axios.create({
    baseURL: baseUrl,
    withCredentials
  })

  // usable for debugging
  axiosInstance.interceptors.request.use((value) => {
    if (testMode) {
      console.log('\n')
      console.log('getAxios request ↓')
      console.dir(value)
      console.log('getAxios caller ↓ ')
      console.trace()
      console.log('\n')
    }
    return value
  })

  axiosInstance.interceptors.response.use(
    (response) =>
      camelcaseKeys(response, {
        deep: true
      }),
    (error) => {
      return Promise.reject(
        error?.response?.data?.error?.message || 'status.info.generic.something_went_wrong'
      )
    }
  )

  return axiosInstance
}
