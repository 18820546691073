import React from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as SuggestionBoxIcon } from '../../../assets/icons/box.svg'
import { ReactComponent as SettingsIcon } from '../../../assets/icons/settings.svg'
import { ReactComponent as UserIcon } from '../../../assets/icons/userBlack.svg'
import { ReactComponent as MessageNewIcon } from '../../../assets/icons/messageNew.svg'
import { ReactComponent as MessageIcon } from '../../../assets/icons/message.svg'
import { NavLink } from 'react-router-dom'
import { PATHS } from '../../../constants/paths'
import { RootState } from '../../../redux/store'
import { useAppSelector } from '../../../redux/storeHooks'

const Wrapper = styled.nav(({ theme }) => {
  const { palette, shadow } = theme
  return css`
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    position: fixed;
    width: 100%;
    height: 84px;
    left: 0px;
    top: 0px;
    background: ${palette.white};
    box-shadow: ${shadow};
    border-radius: 0 0 15px 15px;
    padding: 26px 31px 26px 37px;
    z-index: 1000;

    .right-wrapper {
      display: flex;
      align-items: center;
      > a {
        margin-left: 19px;
        :first-of-type {
          margin-left: 0;
        }
        > .no-new {
          position: relative;
          right: 3px;
        }
      }
    }
  `
})

export const Navbar = () => {
  const conversations = useAppSelector((state: RootState) => state.account?.usersConversations)
  const hasNewMessage = !!conversations?.find((item) => item?.status === 'new')
  return (
    <Wrapper>
      <SuggestionBoxIcon />
      <div className="right-wrapper">
        <NavLink to={`${PATHS.CONVERSATIONS}`}>
          {hasNewMessage ? <MessageNewIcon /> : <MessageIcon className="no-new" />}
        </NavLink>
        <NavLink to={`${PATHS.ACCOUNT}`}>
          <UserIcon />
        </NavLink>
        <NavLink to={`${PATHS.SETTINGS}`}>
          <SettingsIcon />
        </NavLink>
      </div>
    </Wrapper>
  )
}
