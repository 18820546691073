import { IMessage } from '../../types/IMessage'
import { getAxios, to } from '../../utils/axios'
import { config } from '../../utils/config'
import { ENDPOINTS } from '../../utils/endpoints'

async function sendMessage({
  subject,
  message,
  author,
  date,
  conversation
}: Omit<IMessage, 'date' | 'id'> & { date: Date }) {
  const request = getAxios(config.API_URL, true).post(ENDPOINTS.MESSAGE, {
    data: {
      subject,
      message,
      author,
      date,
      conversation
    }
  })

  const [error, response] = await to(request)

  if (error) {
    return [error, null]
  }
  return [null, response.data]
}

export default sendMessage
