import React, { ReactNode, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { fetchUserData } from '../../redux/slices/signInSlice'
import { RootState } from '../../redux/store'
import { useAppDispatch, useAppSelector } from '../../redux/storeHooks'

export const AppWrapper = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch()
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['loggedIn'])
  const user = useAppSelector((state: RootState) => state.signIn.user)
  const [readyToLoadChildren, setReadyToLoadChildren] = useState(false)

  // -- start -- checks if user is authorized and login
  useEffect(() => {
    if (cookies.loggedIn) {
      dispatch(fetchUserData())
    }
  }, [])

  useEffect(() => {
    if (user?.data || user?.error) {
      setReadyToLoadChildren(true)
      if (!user?.data && user?.error) {
        removeCookie('loggedIn')
        window?.dispatchEvent(new CustomEvent('loggedIn'))
      }
    }
    if (!cookies.loggedIn) {
      setReadyToLoadChildren(true)
    }
  }, [user])

  // -- end -- checks if user is authorized and login

  return <>{!!readyToLoadChildren && children}</>
}
