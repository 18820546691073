import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { ICheckBox } from './ICheckbox'

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`

const Label = styled.label<{ disabled?: boolean }>(({ theme, disabled }) => {
  const { palette } = theme
  return css`
    position: relative;
    display: inline-block;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    margin: 0.6rem 0 0.6rem 2.5rem;
    font-size: 18px;
    font-weight: 500;

    > a {
      color: ${palette.violetLight};
      text-decoration: none;

      :visited {
        color: ${palette.violetLight};
      }
    }
  `
})

const Error = styled.p(({ theme }) => {
  const { palette } = theme
  return css`
    color: ${palette.error};
    font-size: 0.75rem;
  `
})

const rotate = keyframes`
 from {
    opacity: 0;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotate(45deg);
  }
`

const Indicator = styled.div<{ checked: boolean }>(({ theme, checked }) => {
  const { palette } = theme
  return css`
    width: 28px;
    height: 28px;
    border: 1px solid #efefef;
    box-shadow: 0px 4px 28px rgba(61, 68, 81, 0.1);
    border-radius: 8px;
    position: absolute;
    top: 0em;
    left: -2.5em;
    background-color: ${checked ? palette.violetLight : palette.white};

    ${Input}:not(:disabled):checked & {
      background: #d1d1d1;
    }

    &::after {
      content: '';
      position: absolute;
      display: none;
    }

    ${Input}:checked + &::after {
      display: block;
      top: 0.3em;
      left: 0.55em;
      width: 8px;
      height: 12px;
      border: solid ${palette.white};
      border-radius: 1px;
      border-width: 0 0.15em 0.15em 0;
      animation-name: ${rotate};
      animation-duration: 0.1s;
      animation-fill-mode: forwards;
    }

    &::disabled {
      cursor: not-allowed;
    }
  `
})

export const Checkbox: React.FC<ICheckBox> = ({
  error,
  label,
  disabled,
  id,
  checked,
  onChange,
  ...restProps
}) => (
  <>
    <Label htmlFor={id} disabled={disabled}>
      {label}
      <Input id={id} type="checkbox" disabled={disabled} checked={checked} onChange={onChange} />
      <Indicator checked={checked} />
    </Label>
    <Error>{error}</Error>
  </>
)
