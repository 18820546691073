import styled, { css } from 'styled-components'

export const Input = styled.input<{ error?: string; secondary?: boolean; height?: string }>(
  ({ theme, error, secondary, height }) => {
    const { palette } = theme
    return css`
      height: ${height || '2.5rem'};
      box-sizing: border-box;
      border: 1px solid #efefef;
      border-radius: 10px;
      color: ${palette.text};
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      padding: 19px 21px;
      outline: none;
      width: 100%;
      ::placeholder {
        color: ${palette.text};
        font-weight: 400;
        font-size: 18px;
        opacity: 1;
      }
      ${error &&
      css`
        border: 2px solid ${palette.error} !important;
      `}
      :focus {
        border: 2px solid ${palette.violet};
      }

      ${secondary &&
      css`
        border: 2px solid #3d4451;
        font-family: 'Exo 2', sans-serif;
        :: placeholder {
          opacity: 1;
        }
      `}
    `
  }
)
