import { createSlice } from '@reduxjs/toolkit'
import getFooterContent from '../../api/textContent/getFooterContent'
import getMainPageContent from '../../api/textContent/getMainPageContent'
import { AppThunk } from '../store'
import { IFooter } from '../../components/molecules/Footer/IFooter'
import { IMainPage } from '../../components/pages/MainPage/IMainPage'
import getCookiesContent from '../../api/textContent/getCookiesContent'
import { ICookiesBar } from '../../components/organisms'

export interface MainPageState {
  mainPageData?: {
    data?: IMainPage
    error?: string
  }
  footerData?: {
    data?: IFooter
    error?: string
  }
  cookiesData?: {
    data?: ICookiesBar
    error?: string
  }
}

const initialState: MainPageState = {}

export const mainPageSlice = createSlice({
  name: 'mainPage',
  initialState,
  reducers: {
    saveMainPageData: (state, action) => {
      state.mainPageData = action.payload
    },
    saveFooterData: (state, action) => {
      state.footerData = action.payload
    },
    saveCookiesData: (state, action) => {
      state.cookiesData = action.payload
    }
  }
})

export const fetchMainPageData =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const [error, response] = await getMainPageContent()
    dispatch(saveMainPageData({ data: response?.data?.attributes, error: error }))
  }

export const fetchFooterData =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const [error, response] = await getFooterContent()
    dispatch(saveFooterData({ data: response?.data?.attributes, error: error }))
  }

export const fetchCookiesData =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const [error, response] = await getCookiesContent()
    dispatch(saveCookiesData({ data: response?.data?.attributes, error: error }))
  }

// Action creators are generated for each case reducer function
export const { saveMainPageData, saveFooterData, saveCookiesData } = mainPageSlice.actions

export default mainPageSlice.reducer
