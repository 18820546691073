import React from 'react'
import PasswordStrengthBar from 'react-password-strength-bar'
import styled, { css } from 'styled-components'
import { config } from '../../../utils/config'
import { TextInput } from '../../atoms'
import { TextInputCopy } from '../../atoms/TextInputCopy/TextInputCopy'
import { TextInputPassword } from '../../atoms/TextInputPassword/TextInputPassword'
import { IRegisterCompanyPage } from '../../pages/RegisterCompanyPage/IRegisterCompanyPage'

const Wrapper = styled.div(({ theme }) => {
  const { palette, shadow } = theme
  return css`
    color: ${palette.black};
    padding: 53px 36px;
    box-shadow: ${shadow};
    border-radius: 12px;
    width: 442px;
    box-sizing: border-box;

    > h5 {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }

    > p {
      margin-top: 4px;
      font-weight: 400;
      font-size: 18px;
      opacity: 0.85;
    }

    > span {
      font-weight: 400;
      font-size: 16px;
      opacity: 0.6;
      margin-bottom: 38px;
    }

    .strength-bar {
      p {
        display: none;
      }
      margin-top: 12px;
    }

    //mobile / tablet horizontal
    @media screen and (max-width: 950px) {
      margin-bottom: 50px;
      width: 100%;
      padding: 37px 14px;
    }
  `
})

interface IRegisterCompanyBox {
  data?: IRegisterCompanyPage
  companyError?: string
  companyValue?: string
  onInputChange?: (field: string, value: string, shouldValidate?: boolean | undefined) => void
  handleBlur: (event: React.FocusEvent<HTMLInputElement, Element>) => void
  passwordError?: string
  passwordValue?: string
}

export const RegisterCompanyBox = ({
  data,
  onInputChange,
  handleBlur,
  companyError,
  companyValue,
  passwordError,
  passwordValue
}: IRegisterCompanyBox) => {
  const linkPlaceholderValue = companyValue?.length
    ? `${data?.registerCompanyBox?.linkPlaceholderSec}${companyValue
        ?.toLowerCase()
        .replace(/ /g, '')}`
    : ''
  const copyValue = `${config.APP_URL}/suggestion-box/${companyValue
    ?.toLowerCase()
    .replace(/ /g, '')}`

  return (
    <Wrapper>
      <h5>{data?.registerCompanyBox?.header}</h5>
      <p>{data?.registerCompanyBox?.description}</p>
      <TextInput
        secondary
        error={companyError}
        label={data?.registerCompanyBox?.companyNameLabel}
        name="company"
        onBlur={handleBlur}
        onChange={onInputChange}
        placeholder={data?.registerCompanyBox?.companyNamePlaceholder}
        value={companyValue}
      />
      <TextInputCopy
        secondary
        copyValue={copyValue}
        error={!companyValue ? data?.registerCompanyBox?.errorCompanyNameRequired : undefined}
        label={data?.registerCompanyBox?.linkLabel}
        name="companyLink"
        onBlur={handleBlur}
        onChange={onInputChange}
        placeholder={data?.registerCompanyBox?.linkPlaceholder}
        value={linkPlaceholderValue}
      />
      <span>{data?.registerCompanyBox?.linkDescription}</span>
      <TextInputPassword
        secondary
        margin="38px 0 0"
        additionalInfo={data?.registerCompanyBox?.passwordDescription}
        autoComplete="new-password"
        name="password"
        error={passwordError}
        label={data?.registerCompanyBox?.passwordLabel}
        onBlur={handleBlur}
        onChange={onInputChange}
        placeholder={data?.registerCompanyBox?.passwordPlaceholder}
        value={passwordValue}
      />
      <PasswordStrengthBar className="strength-bar" password={passwordValue} />
    </Wrapper>
  )
}
