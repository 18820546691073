import { getAxios, to } from '../../utils/axios'
import { config } from '../../utils/config'
import { ENDPOINTS } from '../../utils/endpoints'

export type INewTagBody = {
  name: string
}

async function createNewTag(body: INewTagBody) {
  const request = getAxios(config.API_URL, true).post(ENDPOINTS.TAG, {
    data: body
  })

  const [error, response] = await to(request)

  if (error) {
    return [error, null]
  }
  return [null, response.data]
}

export default createNewTag
