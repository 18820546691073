import React from 'react'
import styled from 'styled-components'
import { IConversationStatus } from '../../../types/IConversation'
import { StateTag } from '../../atoms'

const StateFiltersWrapper = styled.div`
  display: flex;
  //mobile / tablet horizontal
  @media screen and (max-width: 1050px) {
    flex-wrap: wrap;

    > div {
      margin: 0 23px 14px 0;
    }
  }
`

interface IStatusFilters {
  filters: string[]
  setFilters: React.Dispatch<React.SetStateAction<string[]>>
  dataFilters: IConversationStatus[]
}
export const StatusFilters = ({ filters, setFilters, dataFilters }: IStatusFilters) => {
  const isChecked = (id: string) => filters?.includes(id)

  const handleStatusFilterClick = (id: string) => {
    if (filters?.includes(id)) {
      setFilters([])
    } else {
      setFilters([id])
    }
  }
  return (
    <StateFiltersWrapper>
      {dataFilters?.map((item) => (
        <StateTag
          margin="0 23px 0 0"
          key={item?.statusId}
          label={item?.label}
          onClick={() => handleStatusFilterClick(item?.statusId)}
          bulletColor={item?.bulletColor}
          checked={isChecked(item?.statusId)}
        />
      ))}
    </StateFiltersWrapper>
  )
}
