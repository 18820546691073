import React from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as ClockIcon } from '../../../assets/icons/clock.svg'
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg'

interface IMessageStyleProps {
  isReceiver?: boolean
}

const PositionWrapper = styled.div<IMessageStyleProps>(({ isReceiver }) => {
  return css`
    width: 100%;
    display: flex;
    justify-content: ${isReceiver ? 'flex-start' : 'flex-end'};
  `
})

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: fit-content;
  margin-bottom: 41px;
  margin-right: 20px;

  //mobile / tablet horizontal
  @media screen and (max-width: 1050px) {
    width: 100%;
  }

  > span {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    opacity: 0.6;
    margin-top: 11px;

    > svg {
      margin-right: 7px;
    }
  }
`

const Message = styled.div<IMessageStyleProps>(({ theme, isReceiver }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 18px 16px;
    box-sizing: border-box;
    background: ${isReceiver ? palette.text : palette.violetLight};
    border-radius: 10px;
    opacity: ${isReceiver ? '0.5' : '1'};
    width: 512px;
    color: ${palette.white};

    //mobile / tablet horizontal
    @media screen and (max-width: 1050px) {
      width: 100%;
    }

    > h5 {
      font-weight: 700;
      font-size: 21px;
      line-height: 25px;
      margin-bottom: 1rem;
    }

    > p {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      opacity: 0.85;
    }

    > .triangle-wrapper {
      display: flex;
      justify-content: ${isReceiver ? 'flex-start' : 'flex-end'};
      width: 100%;
    }
  `
})

const Triangle = styled.div<IMessageStyleProps>(({ theme, isReceiver }) => {
  const { palette } = theme
  return css`
    width: 0;
    height: 0;
    border-top: 11.25px solid transparent;
    border-bottom: 11.25px solid transparent;
    border-left: 20.53px solid ${isReceiver ? palette.text : palette.violetLight};
    position: relative;

    ${isReceiver &&
    css`
      top: 29px;
      right: 16px;
    `}

    ${!isReceiver &&
    css`
      top: 29px;
      transform: rotate(180deg);
      left: 16px;
    `}
  `
})

interface IMessageItem {
  subject?: string
  message: string
  label?: string
  isReceiver: boolean
  messageJustSend?: boolean
}

export const MessageItem = ({
  subject,
  message,
  label,
  isReceiver,
  messageJustSend
}: IMessageItem) => {
  return (
    <PositionWrapper isReceiver={isReceiver}>
      <MessageWrapper>
        <Message isReceiver={isReceiver}>
          <h5>{subject}</h5>
          <p>{message}</p>
          <div className="triangle-wrapper">
            <Triangle isReceiver={isReceiver} />
          </div>
        </Message>
        {label && (
          <span>
            {messageJustSend ? <CheckIcon /> : <ClockIcon />}
            {messageJustSend ? 'Wysłano' : label}
          </span>
        )}
      </MessageWrapper>
    </PositionWrapper>
  )
}
