import { config } from '../utils/config'
const API_URL = config.API_URL

export interface MediaType {
  url: string
}

export default function getMediaUrl(media?: string | MediaType, isUrl = false) {
  if (isUrl && typeof media === 'string') {
    return media?.startsWith('/') ? `${API_URL}${media}` : media
  }

  function isAnMediaType(obj?: MediaType | string): obj is MediaType {
    return obj && typeof obj !== 'string' ? 'url' in obj : false
  }

  return isAnMediaType(media)
    ? media?.url.startsWith('/')
      ? `${API_URL}${media?.url}`
      : media?.url
    : media || ''
}
