import { getAxios, to } from '../../utils/axios'
import { config } from '../../utils/config'
import { ENDPOINTS } from '../../utils/endpoints'

export type INewDepartmentBody = {
  id: number
  name: string
  admin: number
  receivers: number[]
}

async function updateDepartment(body: INewDepartmentBody) {
  const request = getAxios(config.API_URL, true).put(ENDPOINTS.DEPARTMENT + `/${body.id}`, {
    data: body
  })

  const [error, response] = await to(request)

  if (error) {
    return [error, null]
  }
  return [null, response.data]
}

export default updateDepartment
