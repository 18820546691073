import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as FlagIcon } from '../../../assets/icons/flag.svg'
import { IImportanceTag } from '../../../types/IConversation'

const Wrapper = styled.div`
  display: flex;
`

const MarkerWrapper = styled.div<{ svgColor?: string; hasLabel?: boolean; text?: boolean }>(
  ({ theme, svgColor, hasLabel, text }) => {
    const { palette, shadow } = theme
    return css`
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px;
      width: 51px;
      height: 51px;
      left: 272px;
      background: ${palette.white};
      border: 1px solid #efefef;
      box-shadow: ${shadow};
      border-radius: 32px;

      > svg {
        path {
          stroke: ${svgColor};
        }
        circle {
          stroke: ${svgColor};
        }
      }

      > p {
        font-weight: 500;
        font-size: 16px;
        line-height: 17px;
        opacity: 0.8;
        margin-left: 15px;
      }

      ${hasLabel &&
      css`
        width: auto;
        flex-direction: row;
        border-radius: 10px;
      `}

      ${text &&
      css`
        width: auto;
        flex-direction: row;
        border: none;
        box-shadow: none;
        border-radius: 0;
      `}
    `
  }
)

const DropdownButton = styled.div<{ isOpen?: boolean; squared?: boolean }>(
  ({ theme, isOpen, squared }) => {
    const { palette, shadow } = theme
    return css`
      margin-left: 6px;
      width: 51px;
      height: 51px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      background: ${palette.white};
      border: 1px solid #efefef;
      box-shadow: ${shadow};
      border-radius: 30px;
      cursor: pointer;

      > span {
        ::before {
          display: block;
          content: '';
          position: relative;
          bottom: 3px;
          width: 9.5px;
          height: 9.5px;
          border-left: 2px solid ${palette.text};
          border-top: 2px solid ${palette.text};
          transform: rotate(-135deg);

          ${isOpen &&
          css`
            transform: rotate(45deg);
            bottom: 0px;
          `}
        }
      }

      ${squared &&
      css`
        border-radius: 10px;

        > span {
          ::before {
            top: 2px;
          }
        }
      `}
    `
  }
)

const DropdownWrapper = styled.div<{ isOpen?: boolean }>(({ theme, isOpen }) => {
  const { palette, shadow } = theme
  return css`
    position: absolute;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    min-width: fit-content;
    height: 256px;
    background: ${palette.white};
    box-shadow: ${shadow};
    border-radius: 10px;
    z-index: 100;

    > .top-wrapper {
      display: flex;
      margin-bottom: 20px;
    }

    > .marker-item {
      cursor: pointer;
    }
  `
})

interface IImportanceMarker {
  marker: IImportanceTag
  options?: IImportanceTag[]
  handleChoose?: (id: IImportanceTag['itemId']) => void
}

export const ImportanceMarker = ({ marker, options, handleChoose }: IImportanceMarker) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const getStatusBulletColor = (id: string) => options?.find((item) => item?.itemId === id)?.color

  const handleOpenClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleMarkerClick = (
    id: IImportanceTag['itemId'],
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation()
    if (handleChoose) {
      handleChoose(id)
    }
    setIsDropdownOpen(false)
  }

  const filteredOptions = options?.filter((option) => option?.itemId !== marker?.itemId)

  return (
    <Wrapper>
      {!isDropdownOpen && (
        <>
          <MarkerWrapper svgColor={getStatusBulletColor(marker?.itemId)}>
            <FlagIcon />
          </MarkerWrapper>
          {!!options?.length && (
            <DropdownButton onClick={handleOpenClose} isOpen={isDropdownOpen}>
              <span />
            </DropdownButton>
          )}
        </>
      )}

      {!!isDropdownOpen && (
        <DropdownWrapper>
          <div className="top-wrapper">
            <MarkerWrapper svgColor={getStatusBulletColor(marker?.itemId)} hasLabel>
              <FlagIcon />
              <p>{marker?.label}</p>
            </MarkerWrapper>
            <DropdownButton onClick={handleOpenClose} isOpen={isDropdownOpen} squared>
              <span />
            </DropdownButton>
          </div>
          {filteredOptions?.map((item) => (
            <MarkerWrapper
              className="marker-item"
              key={item?.itemId}
              svgColor={item?.color}
              text
              onClick={(e) => handleMarkerClick(item?.itemId || '', e)}>
              <FlagIcon />
              <p>{item?.label}</p>
            </MarkerWrapper>
          ))}
        </DropdownWrapper>
      )}
    </Wrapper>
  )
}
