import React from 'react'
import styled, { css } from 'styled-components'

export type WrapperSocialButtonProps = {
  width?: string
  margin?: string
  disabled?: boolean
}

const Wrapper = styled.button<WrapperSocialButtonProps>(({ theme, width, margin, disabled }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 18px 23px 18px 30px;
    gap: 10px;
    box-shadow: -2px 12px 60px rgba(40, 40, 40, 0.05);
    background-color: ${palette.white};
    border-radius: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    width: ${width};
    margin: ${margin};

    :hover {
      cursor: pointer;
    }

    ${disabled &&
    css`
      opacity: 0.3;
      :hover {
        cursor: auto;
      }
    `}
  `
})

export type SocialLoginButtonProps = {
  name: string
  icon: string
  width?: string
  margin?: string
  redirectTo: string
  disabled?: boolean
}

export const SocialLoginButton = ({
  name,
  icon,
  width,
  margin,
  redirectTo,
  disabled
}: SocialLoginButtonProps) => {
  return (
    <a href={redirectTo}>
      <Wrapper width={width} margin={margin} disabled={disabled}>
        {name}
        <img alt={name} src={icon} />
      </Wrapper>
    </a>
  )
}
