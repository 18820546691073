import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { enums } from '../../../utils/enums'
import { hexRegex } from '../../../utils/regex'
import { Button } from '../../atoms'
import { TextInputTag } from '../../atoms/TextInputTag/TextInputTag'
import { IRegisterCompanyPage } from '../../pages/RegisterCompanyPage/IRegisterCompanyPage'
import { ISettingsPage } from '../../pages/SettingsPage/ISettingsPage'

const Wrapper = styled.div(({ theme }) => {
  const { palette, shadow } = theme
  return css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: ${palette.black};
    padding: 53px 36px 28px;
    box-shadow: ${shadow};
    border-radius: 12px;
    width: 442px;
    box-sizing: border-box;
    > div {
      > h5 {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 6px;
      }

      > p {
        font-weight: 400;
        font-size: 18px;
        opacity: 0.85;
        margin: 19px 0 42px;
      }

      > span {
        font-weight: 400;
        font-size: 16px;
        opacity: 0.6;
      }

      :nth-of-type(2) {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .strength-bar {
      p {
        display: none;
      }
      margin-top: 12px;
    }

    //mobile / tablet horizontal
    @media screen and (max-width: 950px) {
      width: 100%;
      padding: 37px 14px 28px;
    }
  `
})

interface IColorThemeBox {
  data?: IRegisterCompanyPage | ISettingsPage
  mainColorError?: string
  secColorError?: string
  accountMainColor?: string
  accountSecColor?: string
  handleSetColors: (mainColor: string, secColor: string) => void
}

export const ColorThemeBox = ({
  data,
  mainColorError,
  secColorError,
  accountMainColor,
  accountSecColor,
  handleSetColors
}: IColorThemeBox) => {
  const [tagsInput, setTagInput] = useState('')
  const [tags, setTags] = useState<string[]>([])
  const [tagsInputSec, setTagInputSec] = useState('')
  const [tagsSec, setTagsSec] = useState<string[]>([])
  const [error, setError] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const themeColorMain = localStorage.getItem(enums.themeColorMain)
  const themeColorSec = localStorage.getItem(enums.themeColorSec)

  // get colors from local storage or from data
  useEffect(() => {
    if (themeColorMain) {
      setTags([themeColorMain])
    } else {
      setTags([accountMainColor || data?.colorThemeBox?.mainColorDefault || ''])
    }

    if (themeColorSec) {
      setTagsSec([themeColorSec])
    } else {
      setTagsSec([accountSecColor || data?.colorThemeBox?.secColorDefault || ''])
    }
  }, [data?.colorThemeBox, accountMainColor, accountSecColor])

  // set/reset error
  useEffect(() => {
    if (tags.length && tagsSec.length) {
      setError('')
    } else {
      setError(data?.colorThemeBox?.errorRequired || '')
    }
  }, [tags, tagsSec])

  const handleSaveColors = () => {
    const tag = tags[0]
    const secTag = tagsSec[0]
    if (tag && secTag) {
      const testHex = hexRegex.test(tag) && hexRegex.test(secTag)
      if (testHex) {
        // saving copy in button
        setIsSaving(true)
        // after timeout sets saving button on false
        setTimeout(() => setIsSaving(false), 3000)

        localStorage.setItem(enums.themeColorMain, tag)
        localStorage.setItem(enums.themeColorSec, secTag)
        handleSetColors(tag, secTag)
        window?.dispatchEvent(new CustomEvent(enums.defaultColorsEvent))
      } else {
        setError(data?.colorThemeBox?.errorHex || '')
      }
    } else {
      setError(data?.colorThemeBox?.errorRequired || '')
    }
  }

  const handleBackToOriginalColors = () => {
    localStorage.setItem(enums.themeColorMain, data?.colorThemeBox?.mainColorDefault || '')
    setTags([data?.colorThemeBox?.mainColorDefault || ''])
    localStorage.setItem(enums.themeColorSec, data?.colorThemeBox?.secColorDefault || '')
    setTagsSec([data?.colorThemeBox?.secColorDefault || ''])
    handleSetColors(
      data?.colorThemeBox?.mainColorDefault || '',
      data?.colorThemeBox?.secColorDefault || ''
    )
    window?.dispatchEvent(new CustomEvent(enums.defaultColorsEvent))
  }

  return (
    <Wrapper>
      <div>
        <h5>{data?.colorThemeBox?.header}</h5>
        <span>{data?.colorThemeBox?.optionalInfo}</span>
        <p>{data?.colorThemeBox?.description}</p>
        <TextInputTag
          singleTag
          placeholder={data?.colorThemeBox?.placeholder}
          label={data?.colorThemeBox?.mainColorLabel}
          name="mainColor"
          error={mainColorError}
          tags={tags}
          setTags={setTags}
          setTagInput={setTagInput}
          tagsInput={tagsInput}
          inputLabel={data?.colorThemeBox?.colorPlaceholder}
          tagBackgroundColor={tags[0]}
        />
        <TextInputTag
          singleTag
          placeholder={data?.colorThemeBox?.placeholder}
          error={error || secColorError}
          label={data?.colorThemeBox?.secColorLabel}
          name="secColor"
          tags={tagsSec}
          setTags={setTagsSec}
          setTagInput={setTagInputSec}
          tagsInput={tagsInputSec}
          inputLabel={data?.colorThemeBox?.colorPlaceholder}
          tagColor="#3D4451"
          tagBackgroundColor={tagsSec[0]}
        />
      </div>
      <div>
        <Button type="button" onClick={handleSaveColors}>
          {isSaving ? data?.colorThemeBox?.savingButtonText : data?.colorThemeBox?.acceptButton}
        </Button>
        <Button type="button" text onClick={handleBackToOriginalColors}>
          {data?.colorThemeBox?.backToDefault}
        </Button>
      </div>
    </Wrapper>
  )
}
