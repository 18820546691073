import React from 'react'
import styled, { css } from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Button, Header, TextInput } from '../../atoms'
import { IForgotPassword } from '../../pages/ForgotPasswordPage/IForgotPassword'
import { NavLink } from 'react-router-dom'
import { PATHS } from '../../../constants/paths'
import { IForgotPasswordBody } from '../../../api/signIn/forgotPassword'
import { emailRegex } from '../../../utils/regex'
import { CancelButton } from '../../atoms/CancelButton/CancelButton'

const FormWrapper = styled.div``

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 73px 0 63px;
    background: ${palette.white};
    border-radius: 20px;
    max-width: 340px;

    > p {
      font-size: 18px;
      font-weight: 400;
      padding-bottom: 20px;
    }

    // smaller screen
    @media screen and (min-width: 1080px) and (max-width: 1350px) {
      padding: 63px 0 53px;
    }
    //tablet landscape
    @media screen and (min-width: 950px) and (max-width: 1080px) {
      padding: 48px 0 48px;
      max-width: 316px;
    }

    //mobile / tablet horizontal
    @media screen and (max-width: 950px) {
      padding: 25.1px 0 25.1px;
      max-width: 100%;
    }
  `
})

const BoldedHeader = styled(Header)`
  font-weight: 800;
  margin-bottom: 34px;
  //tablet landscape
  @media screen and (min-width: 950px) and (max-width: 1080px) {
    margin-bottom: 28px;
  }
  //mobile / tablet horizontal
  @media screen and (max-width: 950px) {
    font-size: 40px;
    margin-bottom: 23px;
  }
`

export const ForgotPasswordForm = ({
  data,
  handleSubmit,
  requestError
}: {
  data?: IForgotPassword
  requestError?: string
  handleSubmit: (body: IForgotPasswordBody) => void
}) => {
  return (
    <Wrapper>
      <BoldedHeader>{data?.header}</BoldedHeader>
      <p>{data?.description}</p>
      <FormWrapper>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .required(data?.errorFieldRequired)
              .matches(emailRegex, data?.errorRegex)
          })}
          onSubmit={(values) => {
            handleSubmit({
              email: values.email
            })
          }}>
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setSubmitting
          }) => {
            const onInputChange = (field: string, value: string) => {
              setFieldValue(field, value.trim())
              setSubmitting(false)
            }
            return (
              <form onSubmit={handleSubmit}>
                <TextInput
                  error={
                    touched.email && errors.email
                      ? errors.email
                      : requestError && requestError !== 'sign-out'
                      ? data?.emailError
                      : undefined
                  }
                  label={data?.emailLabel}
                  name="email"
                  onBlur={handleBlur}
                  onChange={onInputChange}
                  placeholder={data?.emailPlaceholder}
                  value={values.email}
                />
                <Button type="submit" disabled={isSubmitting} width="100%" margin="34px 0 12px">
                  {data?.sendButton}
                </Button>
              </form>
            )
          }}
        </Formik>
        <NavLink to={`${PATHS.LOGIN}`}>
          <CancelButton width="100%">{data?.cancelButton}</CancelButton>
        </NavLink>
      </FormWrapper>
    </Wrapper>
  )
}
