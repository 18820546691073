/* eslint-disable no-unused-vars */
export enum enums {
  themeColorMain = 'themeColorMain',
  themeColorSec = 'themeColorSec',
  defaultColorsEvent = 'defaultColorsEvent',
  departments = 'departments',
  company = 'company',
  tags = 'tags',
  cookies = 'cookiesConsent',
  archived = 'archived',
  new = 'new',
  incoming = 'incoming',
  forwarded = 'forwarded',
  blocked = 'blocked',
  worker = 'worker',
  admin = 'admin'
}
