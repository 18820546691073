import React from 'react'
import styled, { css } from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import PasswordStrengthBar from 'react-password-strength-bar'
import { Button, Header } from '../../atoms'
import { passwordRegex } from '../../../utils/regex'
import { IResetPasswordBody } from '../../../api/signIn/resetPassword'
import { IResetPassword } from '../../pages/ResetPasswordPage/IResetPassword'
import { useLocation } from 'react-router-dom'
import { TextInputPassword } from '../../atoms/TextInputPassword/TextInputPassword'

const FormWrapper = styled.div``

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 73px 98px 63px;
    background: ${palette.white};
    border-radius: 20px;
    max-width: 340px;

    .strength-bar {
      p {
        display: none;
      }
      margin-bottom: 25px;
    }

    > p {
      font-size: 18px;
      font-weight: 400;
      padding-bottom: 20px;
    }

    > h5 {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 14px;
      color: ${palette.black};
    }

    // smaller screen
    @media screen and (min-width: 1080px) and (max-width: 1350px) {
      padding: 63px 58px 53px;
    }
    //tablet landscape
    @media screen and (min-width: 950px) and (max-width: 1080px) {
      padding: 48px 38px 48px;
      max-width: 316px;
    }

    //mobile / tablet horizontal
    @media screen and (max-width: 950px) {
      padding: 25.1px 11.5px 25.1px;
      max-width: 100%;
    }
  `
})

const BoldedHeader = styled(Header)`
  font-weight: 800;
  margin-bottom: 34px;
  //tablet landscape
  @media screen and (min-width: 950px) and (max-width: 1080px) {
    margin-bottom: 28px;
  }
  //mobile / tablet horizontal
  @media screen and (max-width: 950px) {
    font-size: 40px;
    margin-bottom: 23px;
  }
`
type IInitialValues = {
  password: string
  passwordConfirmation: string
}

const initialValues: IInitialValues = { passwordConfirmation: '', password: '' }
export const ResetPasswordForm = ({
  data,
  handleSubmit,
  requestError
}: {
  data?: IResetPassword
  requestError?: string
  handleSubmit: (body: IResetPasswordBody) => void
}) => {
  const location = useLocation()
  const code = new URLSearchParams(location.search).get('code')

  return (
    <Wrapper>
      <BoldedHeader>{data?.header}</BoldedHeader>
      <h5>{data?.secHeader}</h5>
      <p>{data?.description}</p>
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .required(data?.passwordRequired)
              .matches(
                passwordRegex, // small char, big char, number,
                data?.errorPasswordRegex
              )
              .min(8, data?.passwordErrorAtLeast8),
            passwordConfirmation: Yup.string()
              .oneOf([Yup.ref('password'), null], data?.passwordMustBeTheSame)
              .required(data?.passwordRequired)
          })}
          onSubmit={(values) => {
            handleSubmit({
              password: values.password,
              passwordConfirmation: values.passwordConfirmation,
              code: code || ''
            })
          }}>
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setSubmitting
          }) => {
            const onInputChange = (field: string, value: string) => {
              setFieldValue(field, value.trim())
              setSubmitting(false)
            }
            return (
              <form onSubmit={handleSubmit}>
                <TextInputPassword
                  autoComplete="new-password"
                  name="password"
                  error={touched.password && errors.password ? errors.password : undefined}
                  label={data?.newPasswordLabel}
                  onBlur={handleBlur}
                  onChange={onInputChange}
                  placeholder={data?.newPasswordPlaceholder}
                  value={values.password}
                />
                <TextInputPassword
                  autoComplete="new-password"
                  label={data?.newSecPasswordLabel}
                  name="passwordConfirmation"
                  error={
                    touched.passwordConfirmation && errors.passwordConfirmation
                      ? errors.passwordConfirmation
                      : undefined
                  }
                  onBlur={handleBlur}
                  onChange={onInputChange}
                  placeholder={data?.newSecPasswordPlaceholder}
                  value={values.passwordConfirmation}
                />
                <PasswordStrengthBar className="strength-bar" password={values.password} />
                <Button type="submit" disabled={isSubmitting} width="100%" margin="34px 0 12px">
                  {data?.loginButton}
                </Button>
              </form>
            )
          }}
        </Formik>
      </FormWrapper>
    </Wrapper>
  )
}
