import React from 'react'
import styled, { css } from 'styled-components'
import { RemovableItem } from '../../atoms/RemovableItem/RemovableItem'
import { IRemovableList } from './IRemovableList'

const List = styled.ul<{ width?: string; margin?: string }>(({ width, margin }) => {
  return css`
    width: ${width};
    margin: ${margin};
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    row-gap: 10px;
  `
})

export const RemovableList = ({
  items,
  width,
  margin,
  handleRemoveItem,
  handleClick
}: IRemovableList) => {
  return (
    <List width={width} margin={margin}>
      {items?.map((item) => (
        <RemovableItem
          key={item.id}
          label={item.label}
          id={item.id}
          handleClick={handleClick}
          handleRemoveItem={handleRemoveItem}
        />
      ))}
    </List>
  )
}
