import { ReactElement } from 'react'
import React from 'react-cookie'
import styled, { css } from 'styled-components'
import { Button } from '../Button/Button'

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    > button {
      color: ${palette.text};
      font-size: 18px;
    }
  `
})

export type ButtonProps = {
  width?: string
  height?: string
  text?: boolean
  textColor?: string
  secondary?: boolean
  margin?: string
  fontSize?: string
  children?: ReactElement | string
  onClick?: () => void
}

export const CancelButton = ({ children, width, onClick }: ButtonProps) => {
  return (
    <Wrapper>
      <Button text width={width} onClick={onClick}>
        {children}
      </Button>
    </Wrapper>
  )
}
