import { IConversationStatus } from '../../types/IConversation'
import { getAxios, to } from '../../utils/axios'
import { config } from '../../utils/config'
import { ENDPOINTS } from '../../utils/endpoints'

async function changeConversationStatus(id: number, status: IConversationStatus['statusId']) {
  const request = getAxios(config.API_URL, true).put(ENDPOINTS.CONVERSATIONS + `/${id}`, {
    data: {
      status
    }
  })

  const [error, response] = await to(request)

  if (error) {
    return [error, null]
  }
  return [null, response.data]
}

export default changeConversationStatus
