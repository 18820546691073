import React from 'react'
import styled, { css } from 'styled-components'
import { ITextInputTag } from './ITextInputTag'
import { Tag } from '../Tag/Tag'
const { v4: uuidv4 } = require('uuid')

const Wrapper = styled.div<{ margin?: string; width?: string }>(({ margin, width }) => {
  return css`
    margin: ${margin || '1.5rem 0'};
    width: ${width || '100%'};
  `
})
const InputWrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-wrap: wrap;
    border: 2px solid #000000;
    border-radius: 10px;
    color: ${palette.text};
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    padding-left: 24px;
    padding-bottom: 7px;

    > p {
      padding-top: 14px;
      font-weight: 400;
      font-size: 18px;
      color: ${palette.text};
      margin-right: 10px;
    }

    > input {
      flex-grow: 2;
      height: 2rem;
      box-sizing: border-box;
      border: none;
      border-radius: 10px;
      margin-top: 6px;
      padding: 19px 21px 19px 7px;
      outline: none;
      color: ${palette.text};
      font-family: 'Exo 2';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      :focus {
        border: none;
        outline: none;
      }
      ::placeholder {
        color: ${palette.text};
        font-weight: 400;
        font-size: 18px;
        opacity: 1;
      }
    }
  `
})

const InputHeader = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    color: ${palette.text};
    margin-bottom: 0.25rem;
    font-weight: 400;
    > label {
      color: ${palette.black};
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      margin-right: 1rem;
      /* white-space: nowrap; */
    }
    > span {
      color: ${palette.black};
      font-weight: 400;
      font-size: 16px;
      opacity: 0.6;
      margin-top: 6px;
    }
  `
})

const ErrorSpan = styled.span(({ theme }) => {
  const { palette } = theme
  return css`
    font-size: 0.75rem;
    color: ${palette.error};
  `
})

export const TextInputTag: React.FC<ITextInputTag> = ({
  setTagInput,
  setTags,
  label,
  tags,
  tagsInput,
  placeholder,
  inputLabel,
  name,
  additionalInfo,
  tagColor,
  tagBackgroundColor,
  singleTag,
  disabled,
  error,
  margin
}) => {
  const hasOneTag = singleTag && tags?.length == 1

  const preventDefaultAndSet = (e: { preventDefault: () => void }, trimmedInput: string) => {
    e.preventDefault()
    setTags((prevState) => [...prevState, trimmedInput])
    setTagInput('')
  }

  const onChange = (e: { target: { value: string } }) => {
    const { value } = e.target
    setTagInput(value)
  }
  const onKeyDown = (e: { preventDefault: () => void; key: string }) => {
    const { key } = e
    const trimmedInput = tagsInput.trim()

    if (key === 'Enter' && trimmedInput.length) {
      preventDefaultAndSet(e, trimmedInput)
    }
  }

  const onBlur = (e: { preventDefault: () => void }) => {
    const trimmedInput = tagsInput.trim()
    if (trimmedInput.length) {
      preventDefaultAndSet(e, trimmedInput)
    }
  }

  const deleteTag = (name: string) => {
    setTags((prevState) => prevState.filter((tag) => tag !== name))
  }

  return (
    <Wrapper margin={margin}>
      <InputHeader>
        <label htmlFor={name}>{label}</label>
        <span>{additionalInfo}</span>
      </InputHeader>
      <InputWrapper>
        {inputLabel && <p>{inputLabel}</p>}
        {tags.map((tag) => (
          <Tag
            color={tagColor}
            backgroundColor={tagBackgroundColor}
            key={uuidv4() + tag}
            label={tag}
            margin="7px 10px 0 0"
            hasDelete
            handleDelete={() => deleteTag(tag)}
          />
        ))}
        {!hasOneTag && (
          <input
            disabled={disabled || hasOneTag}
            name={name}
            value={tagsInput}
            placeholder={hasOneTag ? '' : placeholder}
            onKeyDown={onKeyDown}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      </InputWrapper>
      {error && <ErrorSpan>{error}</ErrorSpan>}
    </Wrapper>
  )
}
