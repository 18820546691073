/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import { AppThunk } from '../store'
import getRegisterCompanyPageContent from '../../api/textContent/getRegisterCompanyPageContent'
import { IRegisterCompanyPage } from '../../components/pages/RegisterCompanyPage/IRegisterCompanyPage'
import { ICompany } from '../../types/ICompany'
import createNewCompany, { INewCompanyBody } from '../../api/signUp/createNewCompany'
import { fetchUserData } from './signInSlice'
import { IDepartment } from '../../components/organisms/RegisterCompanyForm/RegisterCompanyForm'
import createNewDepartment from '../../api/account/createNewDepartment'
import { checkAndCreateUser } from '../../api/account/checkAndCreateUser'
import { ISettingsPage } from '../../components/pages/SettingsPage/ISettingsPage'
import getSettingsPageContent from '../../api/textContent/getSettingsPageContent'
import updateCurrentCompany from '../../api/signUp/updateCompany'
import { ITag } from '../../components/organisms'
import createNewTag from '../../api/account/createNewTag'
import updateDepartment from '../../api/account/updateDepartment'
import getConversationsPageContent from '../../api/textContent/getConversationsPageContent'
import { IConversationsPage } from '../../components/pages/ConversationsPage/IConversationsPage'
import { IConversation, IConversationStatus, IImportanceTag } from '../../types/IConversation'
import changeConversationStatus from '../../api/conversations/changeConversationStatus'
import changeConversationImportance from '../../api/conversations/changeConversationImportance'
import { IMessage } from '../../types/IMessage'
import sendMessage from '../../api/conversations/sendMessage'
import changeConversationDepartment from '../../api/conversations/changeConversationDepartment'

export interface AccountState {
  registerCompanyData?: {
    data?: IRegisterCompanyPage
    error?: string
  }
  settingsData?: {
    data?: ISettingsPage
    error?: string
  }
  conversationsData?: {
    data?: IConversationsPage
    error?: string
  }
  company?: {
    data?: ICompany
    error?: string
  }
  usersDepartments?: IDepartment[]
  usersConversations?: IConversation[]
}

const initialState: AccountState = {}

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    saveRegisterCompanyData: (state, action) => {
      state.registerCompanyData = action.payload
    },
    saveSettingsData: (state, action) => {
      state.settingsData = action.payload
    },
    saveCompanyData: (state, action) => {
      state.company = action.payload
    },
    saveConversationsData: (state, action) => {
      state.conversationsData = action.payload
    },
    saveUsersDepartments: (state, action) => {
      state.usersDepartments = action.payload
    },
    saveUsersConversations: (state, action) => {
      state.usersConversations = action.payload
    }
  }
})

export const fetchRegisterCompanyData =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const [error, response] = await getRegisterCompanyPageContent()
    dispatch(saveRegisterCompanyData({ data: response?.data?.attributes, error: error }))
  }

export const fetchSettingsData =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const [error, response] = await getSettingsPageContent()
    dispatch(saveSettingsData({ data: response?.data?.attributes, error: error }))
  }

export const fetchConversationsData =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const [error, response] = await getConversationsPageContent()
    dispatch(saveConversationsData({ data: response?.data?.attributes, error: error }))
  }

export interface ICreateCompany {
  name: string
  mainColor: string
  secColor: string
  password: string
  usersPermissionsUsers: number[]
  departments: IDepartment[]
}

export const createCompany =
  ({
    name,
    mainColor,
    secColor,
    password,
    usersPermissionsUsers,
    departments
  }: ICreateCompany): AppThunk =>
  async (dispatch): Promise<void> => {
    if (departments?.length) {
      const usersIds: number[] = usersPermissionsUsers
      // creates departments
      const departmentsIds = (
        await Promise.all(
          departments?.map(async (department) => {
            // checks if there is already such a user, if not creates it
            const adminId = await checkAndCreateUser(department?.adminEmail)
            // push user id into usersIds array (admin users in company)
            if (!usersIds.includes(adminId)) usersIds.push(adminId)

            const receiversIds = (
              await Promise.all(
                //filters out admin email if included
                department?.receiversMails
                  ?.filter((mail) => mail !== department.adminEmail)
                  .map(async (mail) => {
                    // checks if there is already such a user, if not creates it
                    return await checkAndCreateUser(mail)
                  })
              )
            )?.filter((item) => typeof item === 'number')
            // pushes admin email if receivers includes
            if (department?.receiversMails?.includes(department.adminEmail)) {
              receiversIds.push(adminId)
            }

            receiversIds.forEach((id) => {
              // push users ids into usersIds array (admin users in company)
              if (!usersIds.includes(id)) usersIds.push(id)
            })

            if (department?.name?.length && adminId && receiversIds?.length) {
              const [errorNewDepartment, newDepartment] = await createNewDepartment({
                name: department?.name,
                admin: adminId,
                receivers: receiversIds
              })
              return newDepartment?.data?.id
            }
          })
        )
      )?.filter((item) => typeof item === 'number')
      // creates company
      if (departmentsIds?.length) {
        const [errorCompany, responseCompany] = await createNewCompany({
          name,
          mainColor,
          secColor,
          password,
          usersPermissionsUsers: usersIds, // admin users in company
          departments: departmentsIds
        })
        if (responseCompany?.data) {
          dispatch(fetchUserData())
        }
      }
    }
  }

export interface IUpdateCompany extends Partial<ICreateCompany> {
  id: number
  tags?: ITag[]
}

export const updateCompany =
  ({ id, name, mainColor, secColor, password, departments, tags }: IUpdateCompany): AppThunk =>
  async (dispatch, state): Promise<void> => {
    const departmentsIds: number[] = []
    const tagsIds: number[] = []
    const usersIds: number[] =
      state().account.company?.data?.usersPermissionsUsers?.map((item) => item?.id) || []

    // departments
    const departmentsFromStore = state().account.company?.data?.departments || []
    // departments already created
    await Promise.all(
      departmentsFromStore.map(async (item) => {
        const alreadyCreatedDepartment = departments?.find(
          (department) => department.name === item.name
        )
        // department already created
        if (item.id && alreadyCreatedDepartment) {
          departmentsIds.push(item.id)

          const hasTheSameAdmin = alreadyCreatedDepartment?.adminEmail === item?.admin?.email
          let hasTheSameReceivers = true
          // check if receivers are the same
          item?.receivers?.forEach((receiver) => {
            if (
              hasTheSameReceivers &&
              !alreadyCreatedDepartment?.receiversMails.includes(receiver.email)
            ) {
              hasTheSameReceivers = false
            }
          })
          //updates department if admin or receivers had changed
          if (!hasTheSameAdmin || !hasTheSameReceivers) {
            const updatedAdminId = await checkAndCreateUser(alreadyCreatedDepartment?.adminEmail)
            // push user id into usersIds array (admin users in company)
            if (!usersIds.includes(updatedAdminId)) usersIds.push(updatedAdminId)

            const updatedReceiversIds = (
              await Promise.all(
                //filters out admin email if included
                alreadyCreatedDepartment?.receiversMails
                  ?.filter((item) => item !== alreadyCreatedDepartment?.adminEmail)
                  .map(async (mail) => {
                    // checks if there is already such a user, if not creates it
                    return await checkAndCreateUser(mail)
                  })
              )
            )?.filter((item) => typeof item === 'number')

            // pushes admin email if receivers includes
            if (
              alreadyCreatedDepartment?.receiversMails?.includes(
                alreadyCreatedDepartment.adminEmail
              )
            ) {
              updatedReceiversIds.push(updatedAdminId)
            }

            updatedReceiversIds.forEach((id) => {
              // push users ids into usersIds array (admin users in company)
              if (!usersIds.includes(id)) usersIds.push(id)
            })

            return await updateDepartment({
              id: item.id,
              name: item.name,
              admin: updatedAdminId,
              receivers: updatedReceiversIds
            })
          }
        }
      })
    )

    // new departments
    const departmentsToCreate = departments?.filter(
      (department) => !departmentsFromStore?.map((item) => item.name).includes(department?.name)
    )

    if (departmentsToCreate?.length) {
      // creates new departments
      const ids: number[] = (
        await Promise.all(
          departmentsToCreate?.map(async (department) => {
            // checks if there is already such a user, if not creates it
            const adminId = await checkAndCreateUser(department?.adminEmail)
            // push user id into usersIds array (admin users in company)
            if (!usersIds.includes(adminId)) usersIds.push(adminId)

            const receiversIds = (
              await Promise.all(
                //filters out admin email if included
                department?.receiversMails
                  ?.filter((item) => item !== department?.adminEmail)
                  ?.map(async (mail) => {
                    // checks if there is already such a user, if not creates it
                    return await checkAndCreateUser(mail)
                  })
              )
            )?.filter((item) => typeof item === 'number')
            // pushes admin email if receivers includes
            if (department?.receiversMails?.includes(department.adminEmail)) {
              receiversIds.push(adminId)
            }
            receiversIds.forEach((id) => {
              // push users ids into usersIds array (admin users in company)
              if (!usersIds.includes(id)) usersIds.push(id)
            })

            if (department?.name?.length && adminId && receiversIds?.length) {
              const [errorNewDepartment, newDepartment] = await createNewDepartment({
                name: department?.name,
                admin: adminId,
                receivers: receiversIds
              })
              return newDepartment?.data?.id
            }
          })
        )
      )?.filter((item) => typeof item === 'number')
      departmentsIds.push(...ids)
    }
    // tags
    const tagsFromStore = state().account.company?.data?.tags || []
    const tagsToCreate = tags?.filter(
      (tag) => !tagsFromStore?.map((item) => item.name).includes(tag?.name)
    )

    // push already created tags (and not deleted) into tagsIds
    const tagsNames = tags?.map((item) => item.name)
    tagsFromStore.forEach((item) => {
      if (tagsNames?.includes(item.name) && item.id) {
        tagsIds.push(item?.id)
      }
    })

    if (tagsToCreate?.length) {
      // creates tag
      const ids = (
        await Promise.all(
          tagsToCreate?.map(async (tag) => {
            if (tag?.name?.length) {
              const [errorNewTag, newTag] = await createNewTag({
                name: tag?.name
              })
              return newTag?.data?.id
            }
          })
        )
      )?.filter((item) => typeof item === 'number')
      tagsIds.push(...ids)
    }

    // update company
    const [errorCompany, responseCompany] = await updateCurrentCompany({
      id,
      name,
      mainColor,
      secColor,
      password,
      usersPermissionsUsers: usersIds, // admin users in company
      departments: departmentsIds,
      tags: tagsIds
    })
    if (responseCompany?.data) {
      dispatch(fetchUserData())
    }
  }

export const updateConversationStatus =
  (id: number, status: IConversationStatus['statusId']): AppThunk =>
  async (dispatch): Promise<void> => {
    const [error, response] = await changeConversationStatus(id, status)
    if (response) {
      dispatch(fetchUserData())
    }
  }

export const updateConversationImportance =
  (id: number, importance: IImportanceTag['itemId']): AppThunk =>
  async (dispatch): Promise<void> => {
    const [error, response] = await changeConversationImportance(id, importance)
    if (response) {
      dispatch(fetchUserData())
    }
  }

export const updateConversationDepartment =
  (id: number, department: number): AppThunk =>
  async (dispatch): Promise<void> => {
    const [error, response] = await changeConversationDepartment(id, department)
    if (response) {
      dispatch(fetchUserData())
    }
  }

export const sendMessageAction =
  ({
    subject,
    message,
    author,
    date,
    conversation
  }: Omit<IMessage, 'date' | 'id'> & { date: Date }): AppThunk =>
  async (dispatch): Promise<void> => {
    const [error, response] = await sendMessage({ subject, message, author, date, conversation })
    if (response) {
      dispatch(fetchUserData())
    }
  }

// Action creators are generated for each case reducer function
export const {
  saveRegisterCompanyData,
  saveCompanyData,
  saveUsersDepartments,
  saveSettingsData,
  saveConversationsData,
  saveUsersConversations
} = accountSlice.actions

export default accountSlice.reducer
