import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import PasswordStrengthBar from 'react-password-strength-bar'
import * as Yup from 'yup'
import { emailRegex, passwordRegex } from '../../../utils/regex'
import { Checkbox, Button, TextInput, SocialLoginButton } from '../../atoms'
import { ISignUp } from '../../organisms/SignUp/ISignUp'
import { PATHS } from '../../../constants/paths'
import { NavLink } from 'react-router-dom'
import { INewUserBody } from '../../../api/signUp/createNewUser'
import { TextInputPassword } from '../../atoms/TextInputPassword/TextInputPassword'
import getMediaUrl from '../../../functions/getMediaUrl'

const Wrapper = styled.div`
  .strength-bar {
    p {
      display: none;
    }
    margin-bottom: 25px;
  }
`

type IInitialValues = {
  email: string
  password: string
  policyAccepted: boolean
}

const initialValues: IInitialValues = { email: '', password: '', policyAccepted: false }

interface ISignUpForm {
  data?: ISignUp
  handleSubmit: (body: INewUserBody) => void
}

export const SignUpForm = ({ data, handleSubmit }: ISignUpForm) => {
  return (
    <Wrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .required(data?.errorFieldRequired)
            .matches(emailRegex, data?.emailErrorIncorrect),
          password: Yup.string()
            .required(data?.errorFieldRequired)
            .matches(
              passwordRegex, // small char, big char, number,
              data?.passwordErrorRegex
            )
            .min(8, data?.passwordErrorAtLeast8),
          policyAccepted: Yup.boolean().required(data?.policyError).oneOf([true], data?.policyError)
        })}
        onSubmit={(values) => {
          handleSubmit({
            email: values.email,
            password: values.password,
            userType: 'admin',
            privacy: values.policyAccepted
          })
        }}>
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setSubmitting
        }) => {
          const onInputChange = (field: string, value: string) => {
            setFieldValue(field, value.trim())
            setSubmitting(false)
          }
          return (
            <form onSubmit={handleSubmit}>
              <TextInput
                error={touched.email && errors.email ? errors.email : undefined}
                label={data?.emailLabel}
                name="email"
                onBlur={handleBlur}
                onChange={onInputChange}
                placeholder={data?.emailPlaceholder}
                value={values.email}
              />
              <TextInputPassword
                autoComplete="new-password"
                name="password"
                error={touched.password && errors.password ? errors.password : undefined}
                label={data?.passwordLabel}
                onBlur={handleBlur}
                onChange={onInputChange}
                placeholder={data?.passwordPlaceholder}
                value={values.password}
              />
              <PasswordStrengthBar className="strength-bar" password={values.password} />
              <Checkbox
                checked={values.policyAccepted}
                onChange={() =>
                  setFieldValue('policyAccepted', !values.policyAccepted ? true : false)
                }
                label={
                  <>
                    {data?.policyLabel}{' '}
                    <a href={data?.policyLink} target="_blank" rel="noreferrer">
                      {data?.policyLabelSec}
                    </a>
                  </>
                }
                error={
                  touched.policyAccepted && errors.policyAccepted
                    ? errors.policyAccepted
                    : undefined
                }
              />
              <Button type="submit" disabled={isSubmitting} width="100%" margin="34px 0 12px">
                {data?.singUpButton}
              </Button>
            </form>
          )
        }}
      </Formik>
      <NavLink to={`${PATHS.LOGIN}`}>
        <Button text textColor="#570DF8" width="100%" onClick={() => {}}>
          {data?.signInButton}
        </Button>
      </NavLink>
      {data?.socialLogins?.social?.map((item) => (
        <SocialLoginButton
          redirectTo={item?.redirectTo}
          key={item?.id}
          width="100%"
          icon={getMediaUrl(item?.icon?.data?.attributes)}
          name={item?.name}
          disabled={!item?.redirectTo}
          margin="1rem 0 0"
        />
      ))}
    </Wrapper>
  )
}
