import React from 'react'
import PasswordStrengthBar from 'react-password-strength-bar'
import styled, { css } from 'styled-components'
import changeCompanyPassword from '../../../api/account/changeCompanyPassword'
import { config } from '../../../utils/config'
import { Button, TextInput } from '../../atoms'
import { TextInputCopy } from '../../atoms/TextInputCopy/TextInputCopy'
import { TextInputPassword } from '../../atoms/TextInputPassword/TextInputPassword'
import { ISettingsPage } from '../../pages/SettingsPage/ISettingsPage'

const Wrapper = styled.div(({ theme }) => {
  const { palette, shadow } = theme
  return css`
    color: ${palette.black};
    padding: 53px 36px 23px;
    box-shadow: ${shadow};
    border-radius: 12px;
    width: 442px;
    box-sizing: border-box;

    > h5 {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }

    > p {
      margin-top: 4px;
      font-weight: 400;
      font-size: 18px;
      opacity: 0.85;
    }

    > span {
      font-weight: 400;
      font-size: 16px;
      opacity: 0.6;
      margin-bottom: 38px;
    }

    .strength-bar {
      p {
        display: none;
      }
      margin-top: 12px;
    }

    > .button-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    //mobile / tablet horizontal
    @media screen and (max-width: 950px) {
      margin-bottom: 50px;
      width: auto;
      padding: 37px 14px;
    }
  `
})

interface ICompanyBox {
  data?: ISettingsPage
  companyId?: number
  companyError?: string
  companyValue?: string
  onInputChange?: (field: string, value: string, shouldValidate?: boolean | undefined) => void
  handleBlur: (event: React.FocusEvent<HTMLInputElement, Element>) => void
  passwordError?: string
  passwordValue?: string
  passwordEditDate?: string
}

export const CompanyBox = ({
  data,
  companyId,
  onInputChange,
  handleBlur,
  companyError,
  companyValue,
  passwordError,
  passwordValue,
  passwordEditDate
}: ICompanyBox) => {
  const linkPlaceholderValue = companyValue?.length
    ? `${data?.companyBox?.linkPlaceholderSec}${companyValue?.toLowerCase().replace(/ /g, '')}`
    : ''
  const copyValue = `${config.APP_URL}/suggestion-box/${companyValue
    ?.toLowerCase()
    .replace(/ /g, '')}`

  // password change suggestion after 30 days after last change
  const passwordEditDateError = () => {
    if (passwordEditDate) {
      const today = new Date()
      const lastEditDate = new Date(passwordEditDate)
      const daysBetween = (today.getTime() - lastEditDate.getTime()) / (1000 * 60 * 60 * 24)
      return daysBetween > 30 ? data?.companyBox?.passwordEditDateOutdated : ''
    }
    return ''
  }

  const handleChangePassword = () => {
    if (passwordValue && companyId) {
      changeCompanyPassword({
        companyId: companyId,
        password: passwordValue,
        passwordEditDate: new Date()
      })
    }
  }

  return (
    <Wrapper>
      <h5>{data?.companyBox?.header}</h5>
      <p>{data?.companyBox?.description}</p>
      <TextInput
        secondary
        disabled
        error={companyError}
        label={data?.companyBox?.companyNameLabel}
        name="company"
        onBlur={handleBlur}
        onChange={onInputChange}
        placeholder={data?.companyBox?.companyNamePlaceholder}
        value={companyValue}
      />
      <TextInputCopy
        secondary
        copyValue={copyValue}
        error={!companyValue ? data?.companyBox?.errorCompanyNameRequired : undefined}
        label={data?.companyBox?.linkLabel}
        name="companyLink"
        onBlur={handleBlur}
        onChange={onInputChange}
        placeholder={data?.companyBox?.linkPlaceholder}
        value={linkPlaceholderValue}
      />
      <span>{data?.companyBox?.linkDescription}</span>
      <TextInputPassword
        secondary
        margin="38px 0 0"
        additionalInfo={data?.companyBox?.passwordDescription}
        autoComplete="new-password"
        name="password"
        error={passwordError || passwordEditDateError()}
        label={data?.companyBox?.passwordLabel}
        onBlur={handleBlur}
        onChange={onInputChange}
        placeholder={data?.companyBox?.passwordPlaceholder}
        value={passwordValue}
      />
      <PasswordStrengthBar className="strength-bar" password={passwordValue} />
      <div className="button-wrapper">
        <Button text type="button" margin="34px 0 0" onClick={handleChangePassword}>
          {data?.companyBox?.changePasswordButton}
        </Button>
      </div>
    </Wrapper>
  )
}
