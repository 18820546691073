import React, { useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import styled, { css } from 'styled-components'
import { fetchCookiesData } from '../../../redux/slices/mainPageSlice'
import { RootState } from '../../../redux/store'
import { useAppDispatch, useAppSelector } from '../../../redux/storeHooks'
import { Button } from '../../atoms'
import { ReactComponent as LeftArrow } from '../../../assets/icons/arrowLeft.svg'
import { CookiesPortal } from '../../molecules'
import { CheckboxListItem } from '../../atoms/CheckboxListItem/CheckboxListItem'
import parse from 'html-react-parser'
import { enums } from '../../../utils/enums'

const Wrapper = styled.div<{ isShown?: boolean }>(({ theme, isShown }) => {
  const { palette } = theme
  return css`
    position: fixed;
    bottom: ${isShown ? '0' : '-406px'};
    display: flex;
    align-items: center;
    color: ${palette.white};
    padding: 48px 79px 44px 113px;
    background-color: ${palette.violetLight};

    // smaller screen
    @media screen and (min-width: 1080px) and (max-width: 1350px) {
      padding: 38px 60px 34px 60px;
    }
    //tablet landscape
    @media screen and (min-width: 950px) and (max-width: 1080px) {
      padding: 38px 30px 34px 30px;
    }
    //mobile / tablet horizontal
    @media screen and (max-width: 950px) {
      padding: 48px 20px 44px 20px;
      top: 0;
    }

    ${isShown &&
    css`
      animation-name: wrapper;
      animation-duration: 1s;
    `}

    @keyframes wrapper {
      0% {
        bottom: -406px;
      }
      35% {
        bottom: -10px;
      }
      100% {
        bottom: 0px;
      }
    }

    .animation-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      position: relative;
      bottom: 0;

      ${isShown &&
      css`
        animation-name: text;
        animation-duration: 1.1s;
      `}

      @keyframes text {
        0% {
          bottom: 0;
        }
        30% {
          bottom: 0px;
        }
        40% {
          bottom: 2px;
        }
        50% {
          bottom: 5px;
        }
        60% {
          bottom: 8px;
        }
        70% {
          bottom: 10px;
        }
        75% {
          bottom: 5px;
        }
        80% {
          bottom: 0px;
        }
        90% {
          bottom: -5px;
        }
        100% {
          bottom: 0px;
        }
      }

      > div {
        display: flex;
        flex-direction: column;

        > h2 {
          color: ${palette.white};
          font-weight: 800;
          font-size: 40px;
          line-height: 48px;
          margin-bottom: 26px;
        }
        > p {
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          opacity: 0.85;
        }

        :last-child {
          align-items: center;
          margin-left: 82px;
        }
      }
      // smaller screen

      //tablet landscape
      @media screen and (min-width: 950px) and (max-width: 1080px) {
        > div {
          :last-child {
            margin-left: 42px;
          }
        }
      }
      //mobile / tablet horizontal
      @media screen and (max-width: 950px) {
        flex-direction: column;
        > div {
          :last-child {
            margin-top: 40px;
            margin-left: 0;
          }
        }
      }
    }
  `
})

const SettingsWrapper = styled.div(({ theme }) => {
  const { palette, shadow } = theme
  return css`
    box-sizing: border-box;
    height: 90%;
    width: 462px;
    padding: 30px 34px;
    background-color: ${palette.white};
    box-shadow: ${shadow};
    border-radius: 15px;
    position: absolute;
    top: calc((100% - 90%) / 2);
    left: calc(50% - 231px);

    .back {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px 15px 5px 10px;
      gap: 10px;
      background-color: ${palette.violetLight};
      box-shadow: ${shadow};
      border-radius: 30px;
      border: none;
      > p {
        color: ${palette.white};
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
      }
    }
    //mobile / tablet horizontal
    @media screen and (max-width: 950px) {
      padding: 48px 20px 44px 20px;
      height: 100vh;
      width: 100vw;
      position: fixed;
      bottom: 0;
      top: 0;
      left: 0;
    }
  `
})

const SettingsContent = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    height: calc(100% - 34px);
    flex-direction: column;
    justify-content: space-between;

    > div {
      display: flex;
      flex-direction: column;
      > h3 {
        padding-left: 32px;
        margin: 18px 0 26px;
        font-weight: 800;
        font-size: 40px;
        line-height: 48px;
        color: ${palette.black};
        width: 80%;
      }
      > p {
        padding-left: 32px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;

        > a {
          color: ${palette.violetLight};
        }
      }
      > button {
      }

      .blur-container {
        width: 100%;
        height: 50px;
        filter: blur(15px);
        position: relative;
        bottom: 50px;
        background: white;
      }

      :last-child {
        align-items: center;
      }
    }
  `
})

const ScrollWrapper = styled.div<{ isBlur?: boolean }>(({ isBlur }) => {
  return css`
    display: flex;
    flex-direction: column;
    height: 400px;
    overflow-y: scroll;
    direction: rtl;

    ${isBlur &&
    css`
      z-index: 0;
      -webkit-mask-image: linear-gradient(black 60%, transparent);
      mask-image: linear-gradient(black 60%, transparent);
    `}
    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: #efefef;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #dcdbdb;
      border-radius: 10px;
    }

    scrollbar-color: #dcdbdb #efefef;

    > div {
      margin: 0 0 27px;
      direction: ltr;
      margin-left: 20px;
    }
  `
})

export const CookiesBar = () => {
  const dispatch = useAppDispatch()
  const data = useAppSelector((state: RootState) => state.mainPage.cookiesData?.data)
  const [isShown, setIsShown] = useState(false)
  const [settings, setSettings] = useState(false)
  const isMobile = useMediaQuery({ query: '(max-width: 950px)' })
  const areCookiesSet = localStorage.getItem(enums.cookies)
  let initialScrollEvent = true

  const scrollingDiv = useRef() as React.RefObject<HTMLDivElement>
  const [isBlur, setIsBlur] = useState(true)
  // eslint-disable-next-line no-unused-vars
  const [required, setRequired] = useState(true)
  const [personalization, setPersonalization] = useState(true)
  const [functional, setFunctional] = useState(true)
  const [analytical, setAnalytical] = useState(true)

  const areAllChecked = required && analytical && functional && personalization

  const handleShow = () => {
    if (!areCookiesSet && !initialScrollEvent) {
      setIsShown(true)
    } else {
      initialScrollEvent = false
    }
  }

  const handleAcceptSettings = () => {
    if (areAllChecked) {
      localStorage.setItem(enums.cookies, 'all')
    } else if (!functional && !analytical && !personalization) {
      localStorage.setItem(enums.cookies, 'required')
    } else {
      localStorage.setItem(
        enums.cookies,
        JSON.stringify({ functional, analytical, personalization, required })
      )
    }
    setIsShown(false)
    setSettings(false)
  }

  const handleAcceptAll = () => {
    localStorage.setItem(enums.cookies, 'all')
    setIsShown(false)
    setSettings(false)
  }

  const handleAcceptRequired = () => {
    localStorage.setItem(enums.cookies, 'required')
    setIsShown(false)
  }

  const handleOpenSettings = () => {
    setSettings(true)
  }

  // fetching text data
  useEffect(() => {
    if (!data) {
      dispatch(fetchCookiesData())
    }
  }, [])

  useEffect(() => {
    if (!isShown && !areCookiesSet) {
      // detect user on scroll
      window.addEventListener('scroll', handleShow)
    } else {
      // removes event when cookies shown
      window.removeEventListener('scroll', handleShow)
    }
    // remove listener
    return () => window.removeEventListener('scroll', handleShow)
  }, [isShown])

  // mobiles view
  useEffect(() => {
    const rootDiv = document.getElementById('root')
    if (rootDiv && isMobile && isShown) {
      rootDiv.style.height = '100vh'
      rootDiv.style.overflow = 'hidden'
    } else if (rootDiv) {
      rootDiv.style.height = 'auto'
      rootDiv.style.overflow = 'visible'
    }
  }, [isShown, isMobile])

  // settings view
  useEffect(() => {
    const rootDiv = document.getElementById('root')
    if (settings && rootDiv) {
      rootDiv.style.height = '100vh'
      rootDiv.style.overflow = 'hidden'
      rootDiv.style.filter = 'blur(1rem)'
    } else if (rootDiv) {
      rootDiv.style.height = 'auto'
      rootDiv.style.overflow = 'visible'
      rootDiv.style.filter = 'none'
    }
  }, [settings, isMobile])

  // handle blur effect on setting portal
  useEffect(() => {
    if (scrollingDiv.current) {
      const scroll = () => {
        if (scrollingDiv.current?.scrollHeight && scrollingDiv.current?.scrollTop) {
          if (
            scrollingDiv.current?.scrollHeight - scrollingDiv.current?.scrollTop - 0.5 <=
            scrollingDiv.current?.clientHeight
          ) {
            setIsBlur(false)
          } else {
            setIsBlur(true)
          }
        }
      }
      scrollingDiv.current.addEventListener('scroll', scroll)
      return () => scrollingDiv.current?.removeEventListener('scroll', scroll)
    }
  }, [settings])

  return (
    <>
      {isShown && !settings && (
        <Wrapper isShown={isShown}>
          <div className="animation-wrapper">
            <div>
              <h2>{data?.header}</h2>
              <p>{data?.description}</p>
            </div>
            <div>
              <Button width="342px" secondary onClick={handleAcceptAll}>
                {data?.acceptAllButton}
              </Button>
              <Button text textColor="#FFFFFF" onClick={handleOpenSettings}>
                {data?.settingsButton}
              </Button>
              <Button text margin="-18px 0 0" textColor="#FFFFFF" onClick={handleAcceptRequired}>
                {data?.acceptRequiredButton}
              </Button>
            </div>
          </div>
        </Wrapper>
      )}
      {settings && (
        <CookiesPortal>
          <SettingsWrapper>
            <button type="button" className="back" onClick={() => setSettings(false)}>
              <LeftArrow />
              <p>{data?.cookiesSettings?.backButton}</p>
            </button>
            <SettingsContent>
              <div>
                <h3>{data?.cookiesSettings?.header}</h3>
                <ScrollWrapper id="portal-scroll-wrapper" isBlur={isBlur} ref={scrollingDiv}>
                  <CheckboxListItem
                    label={data?.cookiesSettings?.requiredHeader}
                    id="required"
                    checked={required}
                    onChange={() => {}}
                    description={data?.cookiesSettings?.requiredDesc}
                  />
                  <CheckboxListItem
                    label={data?.cookiesSettings?.personalizationHeader}
                    id="personalization"
                    checked={personalization}
                    onChange={() => setPersonalization(!personalization)}
                    description={data?.cookiesSettings?.personalizationDesc}
                  />
                  <CheckboxListItem
                    label={data?.cookiesSettings?.functionalHeader}
                    id="functional"
                    checked={functional}
                    onChange={() => setFunctional(!functional)}
                    description={data?.cookiesSettings?.functionalDesc}
                  />
                  <CheckboxListItem
                    label={data?.cookiesSettings?.analyticalHeader}
                    id="analytical"
                    checked={analytical}
                    onChange={() => setAnalytical(!analytical)}
                    description={data?.cookiesSettings?.analyticalDesc}
                  />
                </ScrollWrapper>
              </div>
              <div>
                <Button width="342px" margin="0 0 26px;" onClick={handleAcceptSettings}>
                  {areAllChecked
                    ? data?.cookiesSettings?.acceptAllButton
                    : data?.cookiesSettings?.acceptChosenButton}
                </Button>
                {data?.cookiesSettings?.aboutPrivacy && parse(data?.cookiesSettings?.aboutPrivacy)}
              </div>
            </SettingsContent>
          </SettingsWrapper>
        </CookiesPortal>
      )}
    </>
  )
}
