import React, { useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { ImportanceMarker, TextInput } from '../../atoms'
import { StatusDropdown } from '../StatusDropdown/StatusDropdown'
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/arrowUp.svg'
import { ReactComponent as CheckSquareIcon } from '../../../assets/icons/checkSquare.svg'
import { ReactComponent as ClockIcon } from '../../../assets/icons/clock.svg'
import { IMessage } from '../../../types/IMessage'
import { IConversationStatus, IImportanceTag } from '../../../types/IConversation'
import { useAppDispatch } from '../../../redux/storeHooks'
import {
  sendMessageAction,
  updateConversationImportance,
  updateConversationStatus
} from '../../../redux/slices/accountSlice'
import { getDays } from '../../../functions/getDays'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../../../constants/paths'
import { enums } from '../../../utils/enums'

const Wrapper = styled.div<{ isActive: boolean }>(({ theme, isActive }) => {
  const { palette, shadow } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 35px 50px 38px;
    height: 444px;
    box-sizing: border-box;
    background: ${palette.white};
    box-shadow: ${shadow};
    border-radius: 15px;
    :hover {
      -webkit-box-shadow: 0px 0px 0px 2px ${palette.violetLight};
      -moz-box-shadow: 0px 0px 0px 2px ${palette.violetLight};
      box-shadow: 0px 0px 0px 2px ${palette.violetLight};
    }

    ${isActive &&
    css`
      -webkit-box-shadow: 0px 0px 0px 2px ${palette.violetLight};
      -moz-box-shadow: 0px 0px 0px 2px ${palette.violetLight};
      box-shadow: 0px 0px 0px 2px ${palette.violetLight};
    `}

    // smaller screen
  @media screen and (min-width: 1080px) and (max-width: 1350px) {
      height: 464px;
    }

    //mobile / tablet horizontal
    @media screen and (max-width: 1050px) {
      padding: 31.5px 17px 31.5px;
      height: 464px;
    }
  `
})

const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  > .right-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const BottomWrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    margin-top: 29px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    > .text-content {
      > h4 {
        font-weight: 800;
        font-size: 24px;
        line-height: 29px;
        color: ${palette.black};
        margin-bottom: 5px;
      }
      > span {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        opacity: 0.6;
        > svg {
          margin-right: 7px;
        }
      }
      > p {
        display: block;
        max-height: 118px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        opacity: 0.85;
        margin: 16px 0 39px;
        overflow: hidden;
      }
      //mobile / tablet horizontal
      @media screen and (max-width: 1050px) {
        > p {
          font-size: 14px;
        }
      }
    }
    > .input-wrapper {
      display: flex;
    }
  `
})

const ArchiveButton = styled.div<{ isChecked?: boolean }>(({ theme, isChecked }) => {
  const { palette, shadow } = theme
  return css`
    margin-left: 11px;
    width: 51px;
    height: 51px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background: ${isChecked ? palette.violetLight : palette.white};
    border: 1px solid #efefef;
    box-shadow: ${shadow};
    border-radius: 30px;
    cursor: pointer;

    > span {
      transform: rotate(-135deg);
      position: relative;
      bottom: 5px;

      ::after {
        display: block;
        content: '';
        position: relative;
        width: 2px;
        height: 22.67px;
        background-color: ${isChecked ? palette.white : palette.text};
        border-radius: 0 0 2px 2px;
      }
      ::before {
        display: block;
        content: '';
        position: relative;
        width: 15.58px;
        height: 2px;
        background-color: ${isChecked ? palette.white : palette.text};
        border-radius: 2px 2px 2px 0;
      }
    }
  `
})

const SubmitButton = styled.button<{ isSending?: boolean }>(({ theme, isSending }) => {
  const { palette } = theme
  return css`
    margin-left: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background: ${isSending ? palette.yellow : palette.violetLight};
    border-radius: 10px;
    border: none;
    flex-shrink: 0;
    cursor: pointer;
  `
})

interface IConversationBox {
  id: number
  importance?: IImportanceTag
  importanceOptions?: IImportanceTag[]
  messages: IMessage[]
  status?: IConversationStatus
  statusOptions?: IConversationStatus[]
  oneDayDesc?: string
  otherDaysDesc?: string
  todayDesc?: string
  sendText?: string
  writeBackText?: string
  errorNoEmptyMessage?: string
}

export const ConversationBox = ({
  id,
  importance,
  importanceOptions,
  status,
  statusOptions,
  messages,
  oneDayDesc,
  otherDaysDesc,
  todayDesc,
  sendText,
  writeBackText,
  errorNoEmptyMessage
}: IConversationBox) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isArchived, setIsArchived] = useState(status?.statusId === enums.archived)
  const [newMessage, setNeMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [isSending, setIsSending] = useState(false)
  const [isActive, setIsActive] = useState(false)

  const handleClickConversation = () => {
    navigate(`${PATHS.MESSAGE}/${id}`)
  }

  const handleSendMessage = useCallback(
    (e: { stopPropagation: () => void }) => {
      e.stopPropagation()
      if (newMessage.length) {
        // admin sends message in conversation
        dispatch(
          sendMessageAction({
            subject: undefined,
            message: newMessage,
            author: enums.admin,
            date: new Date(),
            conversation: id
          })
        )
        setIsSending(true)
        setNeMessage('')
        setTimeout(() => setIsSending(false), 5000)
      } else {
        setErrorMessage(errorNoEmptyMessage)
      }
    },
    [newMessage, id, errorNoEmptyMessage]
  )

  const handleChangeStatus = (statusId: IConversationStatus['statusId']) => {
    dispatch(updateConversationStatus(id, statusId))
  }

  const handleChangeImportance = (importanceId: IImportanceTag['itemId']) => {
    dispatch(updateConversationImportance(id, importanceId))
  }

  const handleArchive = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    if (isArchived) {
      handleChangeStatus(enums.incoming)
    } else {
      handleChangeStatus(enums.archived)
    }
  }

  const getLastWorkerMessage = (messages: IMessage[]) =>
    messages?.filter((item) => item?.author === enums.worker)?.[0]

  const message = getLastWorkerMessage(messages)

  useEffect(() => {
    setIsArchived(status?.statusId === enums.archived)
  }, [status?.statusId])

  useEffect(() => {
    if (newMessage.length) setErrorMessage(undefined)
  }, [newMessage])

  return (
    <Wrapper isActive={isActive} onClick={handleClickConversation}>
      <TopWrapper>
        {!!importance && (
          <ImportanceMarker
            marker={importance}
            options={importanceOptions}
            handleChoose={handleChangeImportance}
          />
        )}
        <div className="right-wrapper">
          <StatusDropdown
            status={status}
            options={statusOptions}
            handleChoose={handleChangeStatus}
          />
          <ArchiveButton isChecked={isArchived} onClick={handleArchive}>
            <span />
          </ArchiveButton>
        </div>
      </TopWrapper>
      <BottomWrapper>
        <div className="text-content">
          <h4>{message?.subject}</h4>
          <span>
            <ClockIcon />
            {getDays(message?.date, oneDayDesc || '', otherDaysDesc || '', todayDesc || '')}
          </span>
          <p>{message?.message}</p>
        </div>
        <div className="input-wrapper">
          <TextInput
            margin="0"
            height="60px"
            error={errorMessage}
            name="message"
            onBlur={() => setIsActive(false)}
            onFocus={() => setIsActive(true)}
            onClick={(e) => e.stopPropagation()}
            onChange={(_, value) => setNeMessage(value)}
            placeholder={isSending ? sendText : writeBackText}
            value={newMessage}
          />
          <SubmitButton onClick={handleSendMessage} isSending={isSending}>
            {isSending ? <CheckSquareIcon /> : <ArrowUpIcon />}
          </SubmitButton>
        </div>
      </BottomWrapper>
    </Wrapper>
  )
}
