import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { ICheckBoxListItem } from './ICheckboxListItem'

const Wrapper = styled.div<{ margin?: string }>(({ margin }) => {
  return css`
    margin: ${margin};
  `
})

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`

const Label = styled.label<{ disabled?: boolean }>(({ theme, disabled }) => {
  const { palette } = theme
  return css`
    position: relative;
    display: flex;
    align-items: center;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    margin: 0.6rem 0 0.6rem 0;
    > p {
      color: ${palette.black};
      font-weight: 800;
      font-size: 24px;
      line-height: 29px;
      @media screen and (max-width: 400px) {
        width: 260px;
      }
    }
    > a {
      color: ${palette.violetLight};
      text-decoration: none;

      :visited {
        color: ${palette.violetLight};
      }
    }
  `
})

const Description = styled.p(({ theme }) => {
  const { palette } = theme
  return css`
    color: ${palette.text};
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    opacity: 0.85;
  `
})

const rotate = keyframes`
 from {
    opacity: 0;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotate(45deg);
  }
`

const Indicator = styled.div<{ checked: boolean }>(({ theme, checked }) => {
  const { palette } = theme
  return css`
    width: 31px;
    height: 31px;
    border: 1px solid #efefef;
    box-shadow: 0px 4px 28px rgba(61, 68, 81, 0.1);
    border-radius: 50%;
    position: absolute;
    top: 0em;
    right: 0;
    background-color: ${palette.white};

    ${Input}:not(:disabled):checked & {
      background: #d1d1d1;
    }

    &::after {
      content: '';
      position: absolute;
      display: none;
    }

    ${Input}:checked + &::after {
      display: block;
      top: 0.3em;
      left: 0.5em;
      width: 8px;
      height: 12px;
      border: solid ${palette.text};
      border-radius: 1px;
      border-width: 0 2px 2px 0;
      animation-name: ${rotate};
      animation-duration: 0.1s;
      animation-fill-mode: forwards;
    }

    &::disabled {
      cursor: not-allowed;
    }
  `
})

export const CheckboxListItem: React.FC<ICheckBoxListItem> = ({
  description,
  label,
  disabled,
  id,
  checked,
  onChange,
  margin,
  ...restProps
}) => (
  <Wrapper margin={margin}>
    <Label htmlFor={id} disabled={disabled}>
      <p>{label}</p>
      <Input id={id} type="checkbox" disabled={disabled} checked={checked} onChange={onChange} />
      <Indicator checked={checked} />
    </Label>
    <Description>{description}</Description>
  </Wrapper>
)
