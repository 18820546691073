import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { ImportanceMarker, MessageItem, TextAreaInput, TextInput } from '../../atoms'
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/arrowUp.svg'
import { ReactComponent as CheckSquareIcon } from '../../../assets/icons/checkSquare.svg'
import { ReactComponent as ClockIcon } from '../../../assets/icons/clock.svg'
import { IMessage } from '../../../types/IMessage'
import { IConversationStatus, IImportanceTag } from '../../../types/IConversation'
import { useAppDispatch } from '../../../redux/storeHooks'
import {
  sendMessageAction,
  updateConversationImportance,
  updateConversationStatus
} from '../../../redux/slices/accountSlice'
import { getDays } from '../../../functions/getDays'
import { StatusDropdown } from '../../molecules/StatusDropdown/StatusDropdown'
import { enums } from '../../../utils/enums'
import { useMediaQuery } from 'react-responsive'

const Wrapper = styled.div(({ theme }) => {
  const { palette, shadow } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 35px 50px 38px;
    width: 886px;
    box-sizing: border-box;
    background: ${palette.white};
    box-shadow: ${shadow};
    border-radius: 15px;

    //mobile / tablet horizontal
    @media screen and (max-width: 1050px) {
      width: auto;
      padding: 35px 20px 38px;
    }

    //mobile smallest
    @media screen and (max-width: 380px) {
      width: auto;
      padding: 35px 10px 38px;
    }
  `
})

const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  > .right-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const MiddleWrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    margin-top: 29px;
    > h4 {
      font-weight: 800;
      font-size: 24px;
      line-height: 29px;
      color: ${palette.black};
      margin-bottom: 5px;
    }
    > span {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      opacity: 0.6;
      > svg {
        margin-right: 7px;
      }
    }
  `
})

const MessagesWrapper = styled.div`
  margin: 40px 0;
  height: 520px;
  overflow-y: scroll;

  //mobile / tablet horizontal
  @media screen and (max-width: 1050px) {
    width: 100%;
  }

  > p {
    display: block;
    max-height: 118px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    opacity: 0.85;
    margin: 16px 0 39px;
  }
`

const BottomWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  > .input-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`

const ArchiveButton = styled.div<{ isChecked?: boolean }>(({ theme, isChecked }) => {
  const { palette, shadow } = theme
  return css`
    margin-left: 11px;
    width: 51px;
    height: 51px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background: ${isChecked ? palette.violetLight : palette.white};
    border: 1px solid #efefef;
    box-shadow: ${shadow};
    border-radius: 30px;
    cursor: pointer;

    > span {
      transform: rotate(-135deg);
      position: relative;
      bottom: 5px;

      ::after {
        display: block;
        content: '';
        position: relative;
        width: 2px;
        height: 22.67px;
        background-color: ${isChecked ? palette.white : palette.text};
        border-radius: 0 0 2px 2px;
      }
      ::before {
        display: block;
        content: '';
        position: relative;
        width: 15.58px;
        height: 2px;
        background-color: ${isChecked ? palette.white : palette.text};
        border-radius: 2px 2px 2px 0;
      }
    }
  `
})

const SubmitButton = styled.button<{ isSending?: boolean }>(({ theme, isSending }) => {
  const { palette } = theme
  return css`
    margin-left: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background: ${isSending ? palette.yellow : palette.violetLight};
    border-radius: 10px;
    border: none;
    flex-shrink: 0;
    cursor: pointer;
  `
})

interface IChatBox {
  id: number
  importance?: IImportanceTag
  importanceOptions?: IImportanceTag[]
  messages: IMessage[]
  status?: IConversationStatus
  statusOptions?: IConversationStatus[]
  errorNoEmptyMessage?: string
  errorEmailNotInCompany?: string
  oneDayDesc?: string
  otherDaysDesc?: string
  todayDesc?: string
  sendText?: string
  contentPlaceholderText?: string
  messageSubjectText?: string
}

export const ChatBox = ({
  id,
  importance,
  importanceOptions,
  status,
  statusOptions,
  messages,
  errorNoEmptyMessage,
  oneDayDesc,
  otherDaysDesc,
  todayDesc,
  sendText,
  messageSubjectText,
  contentPlaceholderText
}: IChatBox) => {
  const dispatch = useAppDispatch()
  const isMobile = useMediaQuery({ query: '(max-width: 1050px)' })
  const scroll = useRef() as React.RefObject<HTMLDivElement>
  const [isArchived, setIsArchived] = useState(status?.statusId === enums.archived)
  const [newMessage, setNeMessage] = useState('')
  const [newSubject, setNewSubject] = useState('')
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [isSending, setIsSending] = useState(false)
  const [messageSend, setMessageSend] = useState(false)

  const handleClickConversation = () => {}

  const handleSendMessage = useCallback(
    (e: { stopPropagation: () => void }) => {
      e.stopPropagation()
      if (newMessage.length) {
        // admin sends message in conversation
        dispatch(
          sendMessageAction({
            subject: newSubject,
            message: newMessage,
            author: enums.admin,
            date: new Date(),
            conversation: id
          })
        )
        setIsSending(true)
        setMessageSend(true)
        setNeMessage('')
        setTimeout(() => setIsSending(false), 5000)
      } else {
        setErrorMessage(errorNoEmptyMessage)
      }
    },
    [newMessage, newSubject, id]
  )

  const handleChangeStatus = (statusId: IConversationStatus['statusId']) => {
    dispatch(updateConversationStatus(id, statusId))
  }

  const handleChangeImportance = (importanceId: IImportanceTag['itemId']) => {
    dispatch(updateConversationImportance(id, importanceId))
  }

  const handleArchive = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isArchived) {
      handleChangeStatus(enums.incoming)
    } else {
      handleChangeStatus(enums.archived)
    }
  }

  const getLastWorkerMessage = (messages: IMessage[]) =>
    messages?.filter((item) => item?.author === enums.worker)?.[0]

  const message = getLastWorkerMessage(messages)

  useEffect(() => {
    setIsArchived(status?.statusId === enums.archived)
  }, [status?.statusId])

  useEffect(() => {
    if (newMessage.length) setErrorMessage(undefined)
  }, [newMessage])

  // scroll bottom in messages wrapper
  useEffect(() => {
    if (scroll?.current) {
      scroll.current.scrollTop = scroll.current?.clientHeight
    }
  }, [])

  return (
    <Wrapper onClick={handleClickConversation}>
      <TopWrapper>
        {!!importance && (
          <ImportanceMarker
            marker={importance}
            options={importanceOptions}
            handleChoose={handleChangeImportance}
          />
        )}
        <div className="right-wrapper">
          <StatusDropdown
            status={status}
            options={statusOptions}
            handleChoose={handleChangeStatus}
          />
          {!isMobile && (
            <ArchiveButton isChecked={isArchived} onClick={handleArchive}>
              <span />
            </ArchiveButton>
          )}
        </div>
      </TopWrapper>
      <MiddleWrapper>
        <h4>{message?.subject}</h4>
        <span>
          <ClockIcon />
          {getDays(message?.date, oneDayDesc || '', otherDaysDesc || '', todayDesc || '')}
        </span>
        <MessagesWrapper ref={scroll}>
          {messages?.map((message, index) => (
            <MessageItem
              key={message?.id}
              subject={message?.subject}
              message={message?.message}
              messageJustSend={messages?.length - 1 === index && messageSend}
              label={getDays(message?.date, oneDayDesc || '', otherDaysDesc || '', todayDesc || '')}
              isReceiver={message?.author === enums.worker}
            />
          ))}
        </MessagesWrapper>
      </MiddleWrapper>
      <BottomWrapper>
        <div className="input-wrapper">
          <TextInput
            margin="0"
            height="60px"
            name="subject"
            onBlur={() => {}}
            onClick={(e) => e.stopPropagation()}
            onChange={(_, value) => setNewSubject(value)}
            placeholder={messageSubjectText}
            value={newSubject}
          />
          <TextAreaInput
            margin="8px 0 0"
            height="145px"
            error={errorMessage}
            name="message"
            onBlur={() => {}}
            onClick={(e) => e.stopPropagation()}
            onChange={(_, value) => setNeMessage(value)}
            placeholder={isSending ? sendText : contentPlaceholderText}
            value={newMessage}
          />
        </div>
        <SubmitButton onClick={handleSendMessage} isSending={isSending}>
          {isSending ? <CheckSquareIcon /> : <ArrowUpIcon />}
        </SubmitButton>
      </BottomWrapper>
    </Wrapper>
  )
}
