import { getAxios, to } from '../../utils/axios'
import { config } from '../../utils/config'
import { ENDPOINTS } from '../../utils/endpoints'

async function getUser() {
  const request = getAxios(config.API_URL, true).get(ENDPOINTS.USER_ME)

  const [error, response] = await to(request)

  if (error) {
    return [error, null]
  }
  return [null, response?.data]
}

export default getUser
