import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Button } from '../../atoms'
import { ColorThemeBox, CompanyBox } from '../../molecules'
import { DepartmentsBox } from '../../molecules/DepartmentsBox/DepartmentsBox'
import { enums } from '../../../utils/enums'
import { useMediaQuery } from 'react-responsive'
import { useAppDispatch, useAppSelector } from '../../../redux/storeHooks'
import { updateCompany } from '../../../redux/slices/accountSlice'
import { ISettingsPage } from '../../pages/SettingsPage/ISettingsPage'
import { RootState } from '../../../redux/store'
import { TagsBox } from '../../molecules/TagsBox/TagsBox'

const Wrapper = styled.div`
  width: 100%;
`
const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const RowColumnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  > div:first-child {
    margin-right: 30px;
  }
  > div {
    width: auto;
  }
  margin-bottom: 28px;

  //mobile / tablet horizontal
  @media screen and (max-width: 950px) {
    flex-direction: column;
    > div {
      width: auto;
    }
    > div:first-child {
      margin-right: 0;
    }
  }
`

type IInitialValues = {
  company: string
  password: string
  mainColor: string
  secColor: string
  departments: IDepartment[]
  tags: ITag[]
}

const initialValues: IInitialValues = {
  company: '',
  password: '',
  mainColor: '',
  secColor: '',
  departments: [],
  tags: []
}

interface IRegisterCompanyForm {
  data?: ISettingsPage
  userId: number
}
export interface IDepartment {
  name: string
  adminEmail: string
  receiversMails: string[]
}

export interface ITag {
  name: string
}

export const SettingsForm = ({ data, userId }: IRegisterCompanyForm) => {
  const dispatch = useAppDispatch()
  const company = useAppSelector((state: RootState) => state.account.company?.data)
  const isMobile = useMediaQuery({ query: '(max-width: 950px)' })
  const [initials, setInitials] = useState<IInitialValues>()

  const fetchCurrentDefaultColors = () => {
    const themeColorMain = localStorage.getItem(enums.themeColorMain)
    const themeColorSec = localStorage.getItem(enums.themeColorSec)
    initialValues.mainColor =
      themeColorMain || company?.mainColor || data?.colorThemeBox?.mainColorDefault || ''
    initialValues.secColor =
      themeColorSec || company?.secColor || data?.colorThemeBox?.secColorDefault || ''
  }

  // get company data from data base or from local storage (when edited)
  useEffect(() => {
    // name
    const companyNameLocal = localStorage.getItem(enums.company)
    if (companyNameLocal) {
      initialValues.company = companyNameLocal
    } else {
      initialValues.company = company?.name || ''
    }

    //password
    initialValues.password = company?.password || ''

    // departments
    const departmentsLocal = localStorage.getItem(enums.departments)
    if (departmentsLocal) {
      const departmentsLocalParsed = JSON.parse(departmentsLocal)
      initialValues.departments = departmentsLocalParsed
    } else {
      initialValues.departments =
        company?.departments?.map((department) => {
          return {
            name: department.name,
            adminEmail: department.admin?.email,
            receiversMails: department.receivers?.map((receiver) => receiver.email)
          }
        }) || []
    }

    // tags
    const tagsLocal = localStorage.getItem(enums.tags)
    if (tagsLocal) {
      const tagsLocalParsed = JSON.parse(tagsLocal)
      initialValues.tags = tagsLocalParsed
    } else {
      initialValues.tags =
        company?.tags?.map((tag) => {
          return {
            name: tag.name,
            receiversMails: tag.receivers?.map((receiver) => receiver.email)
          }
        }) || []
    }
    setInitials(initialValues)
  }, [company])

  useEffect(() => {
    //sets initial color from data base or local storage
    fetchCurrentDefaultColors()
  }, [data, company])

  useEffect(() => {
    // detect updating workspace id
    window.addEventListener(enums.defaultColorsEvent, fetchCurrentDefaultColors)
    // remove listener
    return () => window.removeEventListener(enums.defaultColorsEvent, fetchCurrentDefaultColors)
  }, [])

  return (
    <>
      {initials && (
        <Formik
          initialValues={initials}
          validationSchema={Yup.object().shape({
            company: Yup.string().required(data?.errorFieldRequired),
            password: Yup.string().required(data?.errorFieldRequired),
            mainColor: Yup.string().required(data?.errorFieldRequired),
            secColor: Yup.string().required(data?.errorFieldRequired),
            departments: Yup.array().min(1, data?.departmentsBox?.errorAtLeastOneDepartment)
          })}
          onSubmit={(values) => {
            // removes settings from local storage after submit
            localStorage.removeItem(enums.departments)
            localStorage.removeItem(enums.company)
            localStorage.removeItem(enums.tags)
            localStorage.removeItem(enums.themeColorMain)
            localStorage.removeItem(enums.themeColorSec)
            dispatch(
              updateCompany({
                id: company?.id as number,
                name: values?.company,
                password: values?.password,
                mainColor: values?.mainColor,
                secColor: values?.secColor,
                departments: values?.departments,
                tags: values?.tags
              })
            )
          }}>
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setSubmitting
          }) => {
            const onInputChange = (field: string, value: string) => {
              setFieldValue(field, field === 'company' ? value : value.trim())
              setSubmitting(false)
            }
            const onBlur = (e: { target: { value: string; name: string } }) => {
              handleBlur(e)
              // set company in local storage
              if (e.target.name === enums.company) {
                localStorage.setItem(enums.company, e.target.value)
              }
            }
            const onDepartmentsChange = (departments: IDepartment[]) => {
              setFieldValue(enums.departments, departments)
              localStorage.setItem(enums.departments, JSON.stringify(departments) || '')
            }
            const onColorsChange = (mainColor: string, secColor: string) => {
              setFieldValue(enums.themeColorMain, mainColor)
              setFieldValue(enums.themeColorSec, secColor)
            }
            const onTagsChange = (tags: ITag[]) => {
              setFieldValue(enums.tags, tags)
              localStorage.setItem(enums.tags, JSON.stringify(tags) || '')
            }

            const submit = (e?: React.FormEvent<HTMLFormElement> | undefined) => {
              handleSubmit(e)
              setSubmitting(false)
            }
            return (
              <Wrapper>
                <form onSubmit={submit}>
                  <FormWrapper>
                    <RowColumnWrapper>
                      <CompanyBox
                        companyId={company?.id}
                        handleBlur={onBlur}
                        onInputChange={onInputChange}
                        companyError={
                          touched.company && errors.company ? errors.company : undefined
                        }
                        companyValue={values.company}
                        passwordError={
                          touched.password && errors.password ? errors.password : undefined
                        }
                        passwordValue={values.password}
                        data={data}
                        passwordEditDate={company?.passwordEditDate}
                      />
                      <ColorThemeBox
                        handleSetColors={onColorsChange}
                        accountSecColor={company?.secColor}
                        accountMainColor={company?.mainColor}
                        data={data}
                        mainColorError={
                          touched.mainColor && errors.mainColor ? errors.mainColor : undefined
                        }
                        secColorError={
                          touched.secColor && errors.secColor ? errors.secColor : undefined
                        }
                      />
                    </RowColumnWrapper>
                    <TagsBox data={data} tags={values.tags} onTagsChange={onTagsChange} />
                    <DepartmentsBox
                      onSettingsPage
                      departmentsError={
                        (touched.departments && errors.departments
                          ? errors.departments
                          : undefined) as string
                      }
                      data={data}
                      departments={values.departments}
                      onDepartmentsChange={onDepartmentsChange}
                    />
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      width={isMobile ? '318px' : '366px'}
                      margin="80px 0 0">
                      {data?.acceptButton}
                    </Button>
                  </FormWrapper>
                </form>
              </Wrapper>
            )
          }}
        </Formik>
      )}
    </>
  )
}
