import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { CloseButton, Tag } from '../../atoms'
import { ReactComponent as FilterIcon } from '../../../assets/icons/filter.svg'

const TagFiltersWrapper = styled.div`
  display: flex;

  > div {
    margin-right: 10px;
  }

  :last-child {
    margin-right: 0;
  }
`

const FilterButton = styled.button(({ theme }) => {
  const { palette, shadow } = theme
  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: ${palette.violetLight};
    box-shadow: ${shadow};
    border: none;

    cursor: pointer;

    > svg {
      width: 20px;
      height: 18px;
    }
  `
})

const DropdownWrapper = styled.div(({ theme }) => {
  const { shadow } = theme
  return css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 30px 30px 50px 50px;
    box-shadow: ${shadow};
    position: absolute;
    max-width: 273px;
    min-width: 220px;
    max-height: 562px;
    box-sizing: border-box;
    right: 40px;
    top: 140px;
    background: #ffffff;
    border-radius: 20px;
    z-index: 100;

    > .scroll-div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 19px;
      max-height: 480px;
      overflow-y: scroll;

      ::-webkit-scrollbar {
        display: none;
      }

      scrollbar-width: none;

      > div {
        margin-bottom: 12px;
      }

      :last-child {
        margin-bottom: 0;
      }
    }

    //mobile / tablet horizontal
    @media screen and (max-width: 1050px) {
      right: 0;
    }
  `
})

interface ITagFilters {
  dataTags: string[]
  tags: string[]
  setTags: React.Dispatch<React.SetStateAction<string[]>>
}
export const TagFilters = ({ tags, setTags, dataTags }: ITagFilters) => {
  const [isOpen, setIsOpen] = useState(false)

  const checkIfChosen = (id: string) => tags.includes(id)

  const deleteTag = (id: string) => {
    setTags(tags.filter((item) => item !== id))
  }
  const addTag = (id: string) => {
    setTags([...tags, id])
  }

  const handleOpenClose = () => setIsOpen(!isOpen)

  return (
    <TagFiltersWrapper>
      {tags.map((item) => (
        <Tag
          key={item}
          label={'#' + item.toLocaleUpperCase()}
          hasDelete
          opacity="0.7"
          handleDelete={() => deleteTag(item)}
        />
      ))}
      <FilterButton onClick={handleOpenClose}>
        <FilterIcon />
      </FilterButton>
      {isOpen && (
        <DropdownWrapper>
          <CloseButton onClick={handleOpenClose} />
          <div className="scroll-div">
            {dataTags.map((item) => (
              <Tag
                key={item}
                label={'#' + item.toLocaleUpperCase()}
                hasDelete={checkIfChosen(item)}
                backgroundColor={checkIfChosen(item) ? '#FBB13C' : undefined}
                color={checkIfChosen(item) ? '#3D4451' : undefined}
                handleDelete={() => deleteTag(item)}
                handleClick={checkIfChosen(item) ? undefined : () => addTag(item)}
              />
            ))}
          </div>
        </DropdownWrapper>
      )}
    </TagFiltersWrapper>
  )
}
