import { lazy } from 'react'
import { PATHS } from '../constants/paths'

// Placeme components
const MainPage = lazy(() => import('../components/pages/MainPage/MainPage'))
const LogInPage = lazy(() => import('../components/pages/LogInPage/LogInPage'))
const AccountPage = lazy(() => import('../components/pages/AccountPage/AccountPage'))
const ConversationsPage = lazy(
  () => import('../components/pages/ConversationsPage/ConversationsPage')
)
const MessagePage = lazy(() => import('../components/pages/MessagePage/MessagePage'))
const SettingsPage = lazy(() => import('../components/pages/SettingsPage/SettingsPage'))
const RegisterCompanyPage = lazy(
  () => import('../components/pages/RegisterCompanyPage/RegisterCompanyPage')
)
const ForgotPasswordPage = lazy(
  () => import('../components/pages/ForgotPasswordPage/ForgotPasswordPage')
)
const ResetPasswordPage = lazy(
  () => import('../components/pages/ResetPasswordPage/ResetPasswordPage')
)
const ProviderFacebookRedirectPage = lazy(
  () => import('../components/pages/ProviderFacebookRedirectPage/ProviderFacebookRedirectPage')
)

// Auth routes
const SuggestionBoxRoutes = {
  id: 'SuggestionBox',
  rootPath: '/',
  redirect: PATHS.MAIN_PAGE,
  //   guard: HasToBeAuthenticated,
  children: [
    {
      childPath: PATHS.MAIN_PAGE,
      name: 'MainPage',
      component: MainPage
    },
    {
      childPath: PATHS.LOGIN,
      name: 'LoginPage',
      component: LogInPage
    },
    {
      childPath: PATHS.FORGOT_PASSWORD,
      name: 'ForgotPasswordPage',
      component: ForgotPasswordPage
    },
    {
      childPath: PATHS.RESET_PASSWORD,
      name: 'ResetPasswordPage',
      component: ResetPasswordPage
    },
    {
      childPath: PATHS.ACCOUNT,
      name: 'AccountPage',
      component: AccountPage
    },
    {
      childPath: PATHS.CONVERSATIONS,
      name: 'ConversationsPage',
      component: ConversationsPage
    },
    {
      childPath: `${PATHS.MESSAGE}/:id`,
      name: 'MessagePage',
      component: MessagePage
    },
    {
      childPath: PATHS.SETTINGS,
      name: 'SettingsPage',
      component: SettingsPage
    },
    {
      childPath: PATHS.REGISTER_COMPANY,
      name: 'RegisterCompanyPage',
      component: RegisterCompanyPage
    },
    {
      childPath: PATHS.PROVIDER_FACEBOOK,
      name: 'ProviderFacebookRedirect',
      component: ProviderFacebookRedirectPage
    }
  ]
}

export const routes = [SuggestionBoxRoutes]
