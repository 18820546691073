import styled, { css } from 'styled-components'

export const Header = styled.h2(({ theme }) => {
  const { palette } = theme
  return css`
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;

    > span {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: ${palette.text};
      margin-left: 17px;
    }
  `
})
