import { getAxios, to } from '../../utils/axios'
import { config } from '../../utils/config'
import { ENDPOINTS } from '../../utils/endpoints'

export type INewCompanyBody = {
  name: string
  mainColor: string
  secColor: string
  password: string
  usersPermissionsUsers: number[] // list of  users id
  departments: number[] //list of departments id
}

async function createNewCompany(body: INewCompanyBody) {
  const request = getAxios(config.API_URL, true).post(ENDPOINTS.COMPANY, {
    data: {
      ...body,
      users_permissions_users: body.usersPermissionsUsers
    }
  })

  const [error, response] = await to(request)

  if (error) {
    return [error, null]
  }
  return [null, response.data]
}

export default createNewCompany
