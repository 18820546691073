const characterList = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!<>?'
export const createPassword = () => {
  let password = ''
  const characterListLength = characterList.length

  for (let i = 0; i < 15; i++) {
    const characterIndex = Math.round(Math.random() * characterListLength)
    password = password + characterList.charAt(characterIndex)
  }
  return password
}
