import { getAxios, to } from '../../utils/axios'
import { config } from '../../utils/config'
import { ENDPOINTS } from '../../utils/endpoints'

export type IAuthUserBody = {
  identifier: string //email
  password: string
}

async function authUser(body: IAuthUserBody) {
  const request = getAxios(config.API_URL, true).post(ENDPOINTS.AUTH_USER, body)

  const [error, response] = await to(request)
  if (error) {
    return [error, null]
  }
  return [null, response.data]
}

export default authUser
