import { getAxios, to } from '../../utils/axios'
import { config } from '../../utils/config'
import { ENDPOINTS } from '../../utils/endpoints'

export type INewUserBody = {
  email: string
  password: string
  userType: 'admin'
  privacy: boolean
  isAutoCreated?: boolean
}

async function createNewUser(body: INewUserBody) {
  const request = getAxios(config.API_URL, true).post(ENDPOINTS.USER_REGISTER, {
    // confirmation not implemented, so we set confirmation on true while creating user
    confirmed: true,
    // we don't need username, but strapi requires, so I use email
    username: body.email,
    ...body
  })

  const [error, response] = await to(request)

  if (error) {
    return [error, null]
  }
  return [null, response.data]
}

export default createNewUser
