import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Button } from '../../atoms'
import { TextInputTag } from '../../atoms/TextInputTag/TextInputTag'
import { ITag } from '../../organisms/SettingsForm/SettingsForm'
import { ISettingsPage } from '../../pages/SettingsPage/ISettingsPage'
import { RemovableList } from '../RemovableList/RemovableList'

const Wrapper = styled.div<{ isAnyDepartment?: boolean }>(
  ({ isAnyDepartment }) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-bottom: 30px;

    > div:first-child {
      margin-right: 30px;
      width: 100%;
    }
    > div:last-child {
      width: ${isAnyDepartment ? 'auto' : '100%'};
      margin: 0;
      > h5 {
        margin-bottom: 12px;
      }
    }

    //mobile / tablet horizontal
    @media screen and (max-width: 950px) {
      display: block;
      > div:first-child {
        margin-left: 0;
        margin-right: 0;
        width: auto;
      }
      > div:last-child {
        margin-top: 30px;
      }
    }
  `
)

const InnerWrapper = styled.div(({ theme }) => {
  const { palette, shadow } = theme
  return css`
    color: ${palette.black};
    padding: 40px 41px;
    box-shadow: ${shadow};
    border-radius: 12px;
    box-sizing: border-box;

    :last-of-type {
      margin: 30px 0 100px;
    }

    > h5 {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }

    > p {
      font-weight: 400;
      font-size: 18px;
      opacity: 0.85;
      max-width: 540px;
    }

    > span {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      opacity: 0.6;
      margin-bottom: 38px;
    }

    .bottom-wrapper {
      display: flex;
      width: 100%;
      justify-content: flex-start;
    }

    :last-child {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      > div {
        > h5 {
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 7px;
        }

        > p {
          margin-top: 28px;
          font-weight: 400;
          font-size: 18px;
          opacity: 0.85;
          max-width: 540px;
        }

        > span {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          opacity: 0.6;
          margin-bottom: 38px;
        }
      }
      .bottom-wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
      }
    }

    //mobile / tablet horizontal
    @media screen and (max-width: 950px) {
      width: 100%;
      padding: 37px 14px;
      .bottom-wrapper {
        justify-content: center;
      }
      :last-of-type {
        margin: 30px 0 47px;
      }
    }
  `
})

interface ITagsBox {
  data?: ISettingsPage
  tags: ITag[]
  onTagsChange: (tags: ITag[]) => void
}

export const TagsBox = ({ data, tags, onTagsChange }: ITagsBox) => {
  const [error, setError] = useState('')
  const [tagInput, setTagInput] = useState('')
  const [tag, setTag] = useState<string[]>([])

  const tagError = tags.find((item) => item.name.toLocaleLowerCase() === tag[0]?.toLowerCase())
    ? data?.tagsBox?.errorTagExist || ''
    : ''

  const isDisabled = !!tagError.length || !!error.length || !tag.length

  const handleAddTag = () => {
    if (tag[0]) {
      if (!tags.find((item) => item.name === tag[0])) {
        onTagsChange([
          ...tags,
          {
            name: tag[0]
          }
        ])
        setTag([])
      }
    } else {
      setError(data?.departmentsBox?.errorAllFieldsRequired || '')
    }
  }

  const handleDeleteTag = (name: string) => {
    onTagsChange(tags?.filter((tag) => tag.name !== name))
  }

  useEffect(() => {
    if (error && tag[0]) {
      setError('')
    }
  }, [tag])

  return (
    <Wrapper isAnyDepartment={!!tags?.length}>
      <InnerWrapper>
        <h5>{data?.tagsBox?.header}</h5>
        <span>{data?.tagsBox?.additionalInfo}</span>
        <p>{data?.tagsBox?.description}</p>
        <TextInputTag
          singleTag
          error={tagError}
          placeholder={data?.tagsBox?.tagPlaceholder}
          name="tags"
          tags={tag}
          setTags={setTag}
          setTagInput={setTagInput}
          tagsInput={tagInput}
          inputLabel={data?.tagsBox?.inputTagPlaceholder}
        />
        <div className="bottom-wrapper">
          <Button
            type="button"
            width="269px"
            margin="12px 0 0"
            onClick={handleAddTag}
            disabled={isDisabled}>
            {data?.tagsBox?.addNewTagButton}
          </Button>
        </div>
      </InnerWrapper>
      {!!tags?.length && (
        <InnerWrapper>
          <div>
            <h5>{data?.tagsBox?.secHeader}</h5>
            <span>{data?.tagsBox?.secDescription}</span>
            <RemovableList
              margin="41px 0 0"
              handleRemoveItem={handleDeleteTag}
              width="300px"
              items={tags.map((tag) => {
                return { id: tag.name, label: '#' + tag.name.toUpperCase() }
              })}
            />
          </div>
        </InnerWrapper>
      )}
    </Wrapper>
  )
}
