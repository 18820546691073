import React, { Suspense } from 'react'
import { BrowserRouter, Route, useParams, Routes, Navigate } from 'react-router-dom'
import { RouteChildType, RoutePropsType, RouteType } from './routeTypes'

const renderRoutes = (routes: RouteType[]) =>
  routes.map(({ component: Component, children, rootPath, id, redirect }) => {
    const params = useParams()

    const parentRoute =
      redirect || Component ? (
        <Route
          key={`route-${id}`}
          element={
            <>
              {Component && <Component {...params} />}
              {redirect && <Navigate to={redirect} />}
            </>
          }
          path={rootPath}
        />
      ) : null

    const childrenRoutes = children
      ? children.map((element: RouteChildType) => {
          return (
            <Route
              key={`route-${id}-${element.name}`}
              element={
                <>
                  {element.component && <element.component {...params} />}
                  {element.redirect && <Navigate to={element.redirect} />}
                </>
              }
              path={`/${(rootPath + element.childPath).replace(/^\/+/, '')}`}
            />
          )
        })
      : []

    return [parentRoute, ...childrenRoutes]
  })

export const CustomRoutes: React.FC<RoutePropsType> = (props) => {
  const { routes } = props

  return (
    <BrowserRouter>
      <Suspense fallback={<></>}>
        <Routes>
          {renderRoutes(routes)}
          <Route element={<h2>404</h2>} path="*" />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}
