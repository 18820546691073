import React from 'react'
import styled, { css } from 'styled-components'
import { IStateTag } from './IStateTag'

interface IWrapper {
  bulletColor?: string
  checked?: boolean
  margin?: string
  noLabel?: boolean
}

const Wrapper = styled.div<IWrapper>(({ theme, bulletColor, checked, margin, noLabel }) => {
  const { palette } = theme
  return css`
    margin: ${margin};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    padding: 10px;
    background: ${palette.white};
    border: 1px solid #efefef;
    border-radius: 10px;

    :last-of-type {
      margin-right: 0;
    }

    p {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      white-space: nowrap;
    }

    p::before {
      display: block;
      margin-right: ${noLabel ? '0' : '8.54px'};
      box-sizing: border-box;
      content: '';
      width: 9.92px;
      height: 9.92px;
      border: 2px solid ${bulletColor || palette.text};
      border-radius: 50px;
    }

    :hover {
      cursor: pointer;
    }

    ${checked &&
    css`
      background-color: ${palette.yellow};
      p::before {
        background-color: ${bulletColor || palette.text};
        border: 2px solid ${bulletColor || palette.text};
      }
    `}
  `
})

export const StateTag = ({
  label,
  checked,
  bulletColor,
  onClick,
  margin,
  className,
  noLabel
}: IStateTag) => {
  return (
    <Wrapper
      checked={checked}
      bulletColor={bulletColor}
      onClick={onClick}
      margin={margin}
      noLabel={noLabel}
      className={className}>
      <p>{!noLabel ? label : ''}</p>
    </Wrapper>
  )
}
