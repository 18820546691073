import React, { useState } from 'react'
import styled, { css } from 'styled-components'

const Label = styled.div<{ width?: string }>(({ theme, width }) => {
  const { palette } = theme

  return css`
    width: ${width};
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
    border: 2px solid ${palette.text};
    border-radius: 10px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    cursor: pointer;
  `
})

const ArrowButton = styled.button<{ isOpen: boolean }>(({ theme, isOpen }) => {
  const { palette } = theme
  return css`
    border: none;
    background-color: transparent;
    cursor: pointer;

    > span {
      ::before {
        display: block;
        content: '';
        position: relative;
        bottom: 3px;
        width: 9.5px;
        height: 9.5px;
        border-left: 2px solid ${palette.text};
        border-top: 2px solid ${palette.text};
        transform: rotate(-135deg);

        ${isOpen &&
        css`
          transform: rotate(45deg);
          bottom: 0px;
        `}
      }
    }
  `
})

const DropdownWrapper = styled.div<{ width?: string }>(({ theme, width }) => {
  const { palette } = theme
  return css`
    width: ${width};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 14px;
    background: #ffffff;
    box-shadow: 0px 4px 28px rgba(61, 68, 81, 0.1);
    border-radius: 10px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;

    > .top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      cursor: pointer;
    }

    > .option-button {
      text-align: start;
      cursor: pointer;
      width: 100%;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      border: none;
      background-color: ${palette.white};
      padding: 6px 0;

      :hover {
        color: ${palette.violetLight};
      }
    }
  `
})

interface IDropdown {
  width?: string
  className?: string
  placeholder?: string
  value?: {
    id: string | number
    label: string
  }
  options?: {
    id: string | number
    label: string
  }[]

  setValue: React.Dispatch<
    React.SetStateAction<
      | {
          id: string | number
          label: string
        }
      | undefined
    >
  >
}

export const Dropdown = ({
  width,
  value,
  options,
  setValue,
  placeholder,
  className
}: IDropdown) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClickDropdown = () => setIsOpen(!isOpen)
  const handleChoose = (item: { id: string | number; label: string }) => {
    setValue(item)
    handleClickDropdown()
  }

  return (
    <>
      {isOpen ? (
        <DropdownWrapper width={width} className={className}>
          <div className="top" onClick={handleClickDropdown}>
            {value?.label || placeholder}
            <ArrowButton isOpen={isOpen}>
              <span />
            </ArrowButton>
          </div>
          {options?.map((item) => (
            <button
              className="option-button"
              key={item?.id}
              onClick={() => handleChoose(item)}
              type="button">
              {item?.label}
            </button>
          ))}
        </DropdownWrapper>
      ) : (
        <Label width={width} onClick={handleClickDropdown} className={className}>
          {value?.label || placeholder}
          <ArrowButton isOpen={isOpen}>
            <span />
          </ArrowButton>
        </Label>
      )}
    </>
  )
}
