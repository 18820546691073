import styled, { css } from 'styled-components'
import { ReactComponent as BulletViolet } from '../../../assets/icons/bulletViolet.svg'
import { ReactComponent as BulletYellow } from '../../../assets/icons/bulletYellow.svg'
import { Button } from '../../atoms'

const Wrapper = styled.div<{ secondary?: boolean }>(({ theme, secondary }) => {
  const { palette, shadow } = theme
  return css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 46px 32px 35px;
    background: ${secondary ? palette.violetLight : palette.white};
    box-shadow: ${shadow};
    border-radius: 15px;
    width: 231px;

    > div {
      display: flex;
      flex-direction: column;
      ${secondary &&
      css`
        > button {
          color: ${palette.text};
          border: none;
        }
      `}
    }

    :last-of-type {
      margin-left: 19px;
    }
  `
})

const Header = styled.h5<{ secondary?: boolean }>(({ secondary, theme }) => {
  const { palette } = theme
  return css`
    color: ${secondary ? palette.white : palette.text};
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    > span {
      font-weight: 700;
      opacity: 0.5;
      font-size: 18px;
      line-height: 22px;
    }
  `
})

const Description = styled.p<{ secondary?: boolean }>(({ theme, secondary }) => {
  const { palette } = theme
  return css`
    color: ${secondary ? palette.white : palette.black};
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.5;
    margin: 10px 0 46px;
  `
})

const ListItem = styled.div<{ secondary?: boolean }>(({ secondary, theme }) => {
  const { palette } = theme
  return css`
    color: ${secondary ? palette.white : palette.text};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    margin: 9.5px 0;
    width: 100%;

    > svg {
      width: 20px;
      height: 20px;
      margin-right: 13px;
      flex-shrink: 0;
    }
  `
})

export interface ISubscriptionCard {
  header?: string
  description?: string
  list?: { id: number; item: string }[]
  buttonText?: string
  secondary?: boolean
  headerAdditionalInfo?: string
  handleClick?: () => void
}

export const SubscriptionCard = ({
  header,
  description,
  list,
  buttonText,
  handleClick,
  secondary,
  headerAdditionalInfo
}: ISubscriptionCard) => {
  return (
    <Wrapper secondary={secondary}>
      <div>
        <Header secondary={secondary}>
          {header} <span>{secondary && headerAdditionalInfo}</span>
        </Header>
        <Description secondary={secondary}>{description}</Description>
        {list?.map((item) => (
          <ListItem secondary={secondary} key={item?.id}>
            {secondary ? <BulletYellow /> : <BulletViolet />}
            {item?.item}
          </ListItem>
        ))}
      </div>
      <div>
        <Button secondary={secondary} width="100%" onClick={handleClick} margin="45px 0 0">
          {buttonText}
        </Button>
      </div>
    </Wrapper>
  )
}
