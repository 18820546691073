import { createGlobalStyle, css } from 'styled-components'
import { ThemeType } from './theme'

const GlobalStyle = createGlobalStyle<{ theme: ThemeType }>(({ theme }) => {
  const { palette, typography } = theme
  return css`
    html {
      font-size: ${typography.primary.fontSize};
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    * {
      margin: 0;
      padding: 0;
    }
    body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0;
      font-family: ${typography.primary.fontFamily};
      line-height: ${typography.primary.lineHeight};
      color: ${palette.text};
      h2 {
        color: ${palette.black};
      }
      p {
        font-weight: 600;
        font-size: 21px;
      }
      a {
        text-decoration: none;
      }
      #root {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        #container {
          @media screen and (max-width: 576px) {
            width: calc(100% - 30px);
          }
          @media screen and (min-width: 576px) {
            width: 540px;
          }
          @media screen and (min-width: 768px) {
            width: 720px;
          }
          @media screen and (min-width: 850px) {
            width: 820px;
          }
          @media screen and (min-width: 992px) {
            width: 960px;
          }
          @media screen and (min-width: 1200px) {
            width: 1140px;
          }
          @media screen and (min-width: 1400px) {
            width: 1320px;
          }
        }
      }
    }
  `
})

export default GlobalStyle
