import { AnyAction, configureStore } from '@reduxjs/toolkit'
import type { ThunkAction } from 'redux-thunk'
import mainPageReducer from './slices/mainPageSlice'
import signInReducer from './slices/signInSlice'
import accountReducer from './slices/accountSlice'

export const store = configureStore({
  reducer: {
    mainPage: mainPageReducer,
    signIn: signInReducer,
    account: accountReducer
  }
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>
