import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

interface ICookiesPortal {
  children: React.ReactNode
}
const modalRootEl = document.getElementById('cookies-settings-root')
const domElem = document.createElement('div')

export const CookiesPortal = ({ children }: ICookiesPortal) => {
  //portal
  useEffect(() => {
    if (modalRootEl) {
      modalRootEl.appendChild(domElem)
      return () => modalRootEl.removeChild(domElem)
    }
    return () => {}
  }, [domElem])

  return ReactDOM.createPortal(children, domElem)
}
