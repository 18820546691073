export const getDays = (
  date: string,
  oneDayDesc: string,
  otherDaysDesc: string,
  todayDesc: string
) => {
  const newDate = new Date(date)
  const today = new Date()
  const days = Math.floor((today.getTime() - newDate.getTime()) / (1000 * 60 * 60 * 24))
  switch (days) {
    case 1:
      return days + ' ' + oneDayDesc
    case 0:
      return todayDesc
    default:
      return days + ' ' + otherDaysDesc
  }
}
