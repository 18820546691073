import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { ReactComponent as UserIcon } from '../../../assets/icons/user.svg'
import { PATHS } from '../../../constants/paths'
import { updateConversationDepartment } from '../../../redux/slices/accountSlice'
import { RootState } from '../../../redux/store'
import { useAppDispatch, useAppSelector } from '../../../redux/storeHooks'
import { Button, CloseButton, Dropdown } from '../../atoms'
import { TextInputTag } from '../../atoms/TextInputTag/TextInputTag'

const Wrapper = styled.div`
  margin-left: 16px;
`

const UserButton = styled.button(({ theme }) => {
  const { palette, shadow } = theme
  return css`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${palette.violetLight};
    box-shadow: ${shadow};
    border: none;

    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 100px;
  `
})

const Modal = styled.div(({ theme }) => {
  const { palette, shadow } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 40px 50px;
    width: 685px;
    box-sizing: border-box;
    background: ${palette.white};
    box-shadow: ${shadow};
    border-radius: 15px;
    position: absolute;
    z-index: 300;

    //mobile / tablet horizontal
    @media screen and (max-width: 1050px) {
      width: 100%;
      top: 20px;
      left: 0;
      padding: 40px 37px;

      .forward-dropdown {
        width: 100%;
      }
    }

    > h5 {
      color: ${palette.black};
      margin: 20px 0 5px;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
    }
    > p {
      margin-bottom: 25px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
    > span {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
    }
  `
})

const BottomWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const ForwardConversation = ({ conversationId }: { conversationId: number }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const data = useAppSelector((state: RootState) => state.account?.conversationsData?.data)
  const company = useAppSelector((state: RootState) => state.account?.company?.data)
  const conversations = useAppSelector((state: RootState) => state.account?.usersConversations)
  const [isOpen, setIsOpen] = useState(false)
  const [email, setEmail] = useState<string[]>([])
  const [emailInput, setEmailInput] = useState('')
  const [error, setError] = useState<string | undefined>(undefined)
  const [department, setDepartment] = useState<{ id: string | number; label: string }>()

  const companyDepartments = company?.departments?.map((item) => {
    return {
      id: item.id as number | string,
      label: item?.name
    }
  })

  const conversationsDepartment = conversations?.find(
    (conversation) => conversation.id === conversationId
  )?.department?.name

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const forwardConversation = () => {
    if (department?.id && !error) {
      dispatch(updateConversationDepartment(conversationId, Number(department?.id)))
      navigate(PATHS.CONVERSATIONS)
    }
  }

  const checkEmail = () => {
    if (email[0]) {
      const isEmailInCompany = company?.usersPermissionsUsers
        ?.map((item) => item?.email)
        ?.includes(email[0])

      if (!isEmailInCompany && email[0]) {
        setError(data?.errorEmailNotInCompany)
      } else {
        const chosenAdminsDepartment = company?.departments?.find(
          (item) =>
            item?.admin?.email === email[0] ||
            item?.receivers?.map((receiver) => receiver?.email)?.includes(email[0])
        )
        if (chosenAdminsDepartment && chosenAdminsDepartment?.id) {
          setDepartment({ id: chosenAdminsDepartment?.id, label: chosenAdminsDepartment?.name })
        }
        setError(undefined)
      }
    }
  }

  useEffect(() => {
    checkEmail()
  }, [email])

  useEffect(() => {
    if (department) {
      if (
        company?.departments?.find((item) => item?.id === department?.id)?.admin?.email !==
          email[0] &&
        !company?.departments
          ?.find((item) => item?.id === department?.id)
          ?.receivers?.map((receiver) => receiver?.email)
          ?.includes(email[0])
      ) {
        const chosenDepartmentAdminEmail = company?.departments?.find(
          (item) => item?.id === department?.id
        )?.admin?.email

        if (chosenDepartmentAdminEmail) setEmail([chosenDepartmentAdminEmail])
      }
    }
  }, [department])

  return (
    <Wrapper>
      {!isOpen && (
        <UserButton type="button" className="user-button" onClick={handleClick}>
          <UserIcon />
        </UserButton>
      )}
      {!!isOpen && (
        <Modal>
          <CloseButton onClick={handleClick} />
          <h5>{data?.forwardConversationHeader}</h5>
          <p>{data?.forwardConversationDesc}</p>
          <span>
            {data?.forwardConversationFrom} {conversationsDepartment}
          </span>
          <TextInputTag
            margin="25px 0"
            singleTag
            inputLabel="Do:"
            error={error}
            placeholder={data?.forwardConversationTypeEmail}
            name="email"
            tags={email}
            setTags={setEmail}
            setTagInput={setEmailInput}
            tagsInput={emailInput}
          />
          <Dropdown
            className="forward-dropdown"
            placeholder={data?.forwardConversationDepartment}
            value={department}
            options={companyDepartments}
            width="366px"
            setValue={setDepartment}
          />
          <BottomWrapper>
            <Button margin="35px 0 0" width="222px" onClick={forwardConversation}>
              {data?.forwardConversationSend}
            </Button>
          </BottomWrapper>
        </Modal>
      )}
    </Wrapper>
  )
}
