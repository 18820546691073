import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { emailRegex } from '../../../utils/regex'
import { Button } from '../../atoms'
import { TextInputTag } from '../../atoms/TextInputTag/TextInputTag'
import { IDepartment } from '../../organisms/RegisterCompanyForm/RegisterCompanyForm'
import { IRegisterCompanyPage } from '../../pages/RegisterCompanyPage/IRegisterCompanyPage'
import { ISettingsPage } from '../../pages/SettingsPage/ISettingsPage'
import { RemovableList } from '../RemovableList/RemovableList'

const Wrapper = styled.div<{ isSettings?: boolean; isAnyDepartment?: boolean }>(
  ({ isSettings, isAnyDepartment }) => css`
    ${isSettings &&
    css`
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      flex-grow: 1;

      > div:first-child {
        margin-left: 30px;
        width: 85%;
      }
      > div:last-child {
        width: ${isAnyDepartment ? 'auto' : '100%'};
        margin: 0;
        > h5 {
          margin-bottom: 12px;
        }
      }

      //mobile / tablet horizontal
      @media screen and (max-width: 950px) {
        display: block;
        > div:first-child {
          margin-left: 0;
          width: auto;
        }
        > div:last-child {
          margin-top: 30px;
        }
      }
    `}
  `
)

const InnerWrapper = styled.div(({ theme }) => {
  const { palette, shadow } = theme
  return css`
    color: ${palette.black};
    padding: 40px 41px;
    box-shadow: ${shadow};
    border-radius: 12px;
    width: 914px;
    box-sizing: border-box;

    :last-of-type {
      margin: 30px 0 100px;
    }

    > h5 {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }

    > p {
      margin-top: 4px;
      font-weight: 400;
      font-size: 18px;
      opacity: 0.85;
    }

    > span {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      opacity: 0.6;
      margin-bottom: 38px;
    }

    .bottom-wrapper {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }

    //mobile / tablet horizontal
    @media screen and (max-width: 950px) {
      width: 100%;
      padding: 37px 14px;
      .bottom-wrapper {
        justify-content: center;
        flex-direction: column-reverse;
        align-items: center;
      }
      :last-of-type {
        margin: 30px 0 47px;
      }
    }
  `
})

interface IDepartmentsBox {
  data?: IRegisterCompanyPage | ISettingsPage
  departments: IDepartment[]
  departmentsError?: string
  onDepartmentsChange: (departments: IDepartment[]) => void
  onSettingsPage?: boolean
}

export const DepartmentsBox = ({
  data,
  departments,
  departmentsError,
  onDepartmentsChange,
  onSettingsPage
}: IDepartmentsBox) => {
  const [error, setError] = useState('')
  const [departmentInput, setDepartmentInput] = useState('')
  const [department, setDepartment] = useState<string[]>([])
  const [adminEmailInput, setAdminEmailInput] = useState('')
  const [adminEmail, setAdminEmail] = useState<string[]>([])
  const [receiversEmailsInput, setReceiversEmailsInput] = useState('')
  const [receiversEmails, setReceiversEmails] = useState<string[]>([])
  const [editedName, setEditedName] = useState<string[]>([])
  const [editedTempName, setEditedTempName] = useState<string[]>([])
  const [editedAdminEmail, setEditedAdminEmail] = useState<string[]>([])
  const [editedReceiversEmails, setEditedReceiversEmails] = useState<string[]>([])

  const isDuplicated = (emails: string[]) =>
    Object.values(
      emails.reduce((accumulator: { [key: string]: number }, value) => {
        return { ...accumulator, [value]: (accumulator[value] || 0) + 1 }
      }, {})
    ).find((val) => val > 1)

  const emailError = (emails: string[]) => {
    const isErrorRegex = emails?.find((email) => !emailRegex.test(email))
    return isErrorRegex
      ? data?.departmentsBox?.errorEmailRegex || ''
      : '' || isDuplicated(emails)
      ? data?.departmentsBox?.errorEmailDuplicates || ''
      : ''
  }

  const isEdited = editedName?.length || editedAdminEmail?.length || editedReceiversEmails?.length

  const departmentsEditedOrNot = isEdited
    ? departments?.filter((item) => item?.name !== editedTempName[0])
    : departments

  const departmentError = departmentsEditedOrNot.find(
    (item) =>
      item.name.toLocaleLowerCase() ===
      (isEdited ? editedName[0]?.toLocaleLowerCase() : department[0]?.toLowerCase())
  )
    ? data?.departmentsBox?.errorDepartmentExist || ''
    : ''

  const isDisabled =
    !department[0] ||
    !adminEmail[0] ||
    !receiversEmails.length ||
    !!departmentError.length ||
    !!emailError(adminEmail).length ||
    !!emailError(receiversEmails).length ||
    !!error.length

  const isDisabledEdited =
    !editedName[0] ||
    !editedAdminEmail[0] ||
    !editedReceiversEmails.length ||
    !!departmentError.length ||
    !!emailError(editedAdminEmail).length ||
    !!emailError(editedReceiversEmails).length ||
    !!error.length

  const handleAddDepartment = () => {
    if (department[0] && adminEmail[0] && receiversEmails.length) {
      if (!departments.find((item) => item.name === department[0])) {
        onDepartmentsChange([
          ...departments,
          {
            name: department[0],
            adminEmail: adminEmail[0],
            receiversMails: receiversEmails
          }
        ])
        setDepartment([])
        setAdminEmail([])
        setReceiversEmails([])
      }
    } else {
      setError(data?.departmentsBox?.errorAllFieldsRequired || '')
    }
  }

  const handleDeleteDepartment = (name: string) => {
    onDepartmentsChange(departments?.filter((depart) => depart.name !== name))
  }

  const handleEditAction = (name: string) => {
    const department = departments.find((item) => item.name === name)
    if (department?.name) setEditedName([department?.name])
    if (department?.name) setEditedTempName([department?.name])
    if (department?.adminEmail) setEditedAdminEmail([department?.adminEmail])
    if (department?.receiversMails) setEditedReceiversEmails(department?.receiversMails)
  }

  const handleCancelEdit = () => {
    setEditedName([])
    setEditedTempName([])
    setEditedAdminEmail([])
    setEditedReceiversEmails([])
  }

  const handleEditedSaveChanges = () => {
    if (editedName[0] && editedAdminEmail[0] && editedReceiversEmails.length) {
      onDepartmentsChange([
        ...departmentsEditedOrNot,
        {
          name: editedName[0],
          adminEmail: editedAdminEmail[0],
          receiversMails: editedReceiversEmails
        }
      ])
      setEditedName([])
      setEditedTempName([])
      setEditedAdminEmail([])
      setEditedReceiversEmails([])
    } else {
      setError(data?.departmentsBox?.errorAllFieldsRequired || '')
    }
  }

  // auto adds admin email to receivers emails
  useEffect(() => {
    if (adminEmail[0]?.length && !receiversEmails.includes(adminEmail[0]))
      setReceiversEmails([adminEmail[0], ...receiversEmails])
  }, [adminEmail])

  useEffect(() => {
    if (error && department[0] && adminEmail[0] && receiversEmails.length) {
      setError('')
    }
  }, [department, adminEmail, receiversEmails])

  return (
    <Wrapper isSettings={onSettingsPage} isAnyDepartment={!!departments?.length}>
      <InnerWrapper>
        <h5>{isEdited ? data?.departmentsBox?.headerEdit : data?.departmentsBox?.header}</h5>
        <span>{data?.departmentsBox?.description}</span>
        <TextInputTag
          singleTag
          error={departmentError}
          placeholder={data?.departmentsBox?.namePlaceholder}
          label={data?.departmentsBox?.nameLabel}
          name="department"
          tags={isEdited ? editedName : department}
          setTags={isEdited ? setEditedName : setDepartment}
          setTagInput={setDepartmentInput}
          tagsInput={departmentInput}
          inputLabel={data?.departmentsBox?.departmentPlaceholder}
        />
        <TextInputTag
          singleTag
          error={isEdited ? emailError(editedAdminEmail) : emailError(adminEmail)}
          placeholder={data?.departmentsBox?.adminEmailPlaceholder}
          label={data?.departmentsBox?.adminEmailLabel}
          name="adminEmail"
          tags={isEdited ? editedAdminEmail : adminEmail}
          setTags={isEdited ? setEditedAdminEmail : setAdminEmail}
          setTagInput={setAdminEmailInput}
          tagsInput={adminEmailInput}
        />
        <TextInputTag
          error={
            isEdited
              ? emailError(editedReceiversEmails)
              : emailError(receiversEmails) || error || departmentsError
          }
          placeholder={data?.departmentsBox?.receiverEmailPlaceholder}
          label={data?.departmentsBox?.receiverEmailLabel}
          name="receiversEmails"
          tags={isEdited ? editedReceiversEmails : receiversEmails}
          setTags={isEdited ? setEditedReceiversEmails : setReceiversEmails}
          setTagInput={setReceiversEmailsInput}
          tagsInput={receiversEmailsInput}
        />
        <div className="bottom-wrapper">
          {isEdited ? (
            <>
              <Button text type="button" margin="12px 0 0" onClick={handleCancelEdit}>
                {data?.departmentsBox?.cancelButton}
              </Button>
              <Button
                type="button"
                width="269px"
                margin="12px 0 0"
                onClick={handleEditedSaveChanges}
                disabled={isDisabledEdited}>
                {data?.departmentsBox?.saveChangesButton}
              </Button>
            </>
          ) : (
            <Button
              type="button"
              width="269px"
              margin="12px 0 0"
              onClick={handleAddDepartment}
              disabled={isDisabled}>
              {data?.departmentsBox?.acceptButton}
            </Button>
          )}
        </div>
      </InnerWrapper>
      {!!departments?.length && (
        <InnerWrapper>
          <h5>{data?.departmentsBox?.secHeader}</h5>
          <span>{data?.departmentsBox?.secDescription}</span>
          <RemovableList
            margin="41px 0 0"
            handleClick={handleEditAction}
            handleRemoveItem={handleDeleteDepartment}
            width="300px"
            items={departmentsEditedOrNot.map((depart) => {
              return { id: depart.name, label: '#' + depart.name.toUpperCase() }
            })}
          />
        </InnerWrapper>
      )}
    </Wrapper>
  )
}
