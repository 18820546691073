import { getAxios, to } from '../../utils/axios'
import { config } from '../../utils/config'
import { ENDPOINTS } from '../../utils/endpoints'

async function changeConversationStatus(id: number, department: number) {
  const request = getAxios(config.API_URL, true).put(ENDPOINTS.CONVERSATIONS + `/${id}`, {
    data: {
      department,
      status: 'new'
    }
  })

  const [error, response] = await to(request)

  if (error) {
    return [error, null]
  }
  return [null, response.data]
}

export default changeConversationStatus
