import styled, { css } from 'styled-components'

export type ButtonProps = {
  width?: string
  height?: string
  text?: boolean
  textColor?: string
  secondary?: boolean
  margin?: string
  fontSize?: string
}

export const Button = styled.button<ButtonProps>(
  ({ theme, width, secondary, text, margin, textColor, height, fontSize }) => {
    const { palette } = theme
    return css`
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 18px 25px;
      gap: 10px;
      background-color: ${palette.violetLight};
      border-radius: 9px;
      outline: none;
      border: none;
      font-size: ${fontSize || '20px'};
      line-height: 24px;
      font-weight: 400;
      color: ${palette.white};
      width: ${width};
      height: ${height};
      margin: ${margin};

      transition: all 0.2s;

      :hover {
        color: ${palette.violetLight};
        cursor: pointer;
        background-color: ${palette.white};
        -webkit-box-shadow: inset 0px 0px 0px 2px ${palette.violetLight};
        -moz-box-shadow: inset 0px 0px 0px 2px ${palette.violetLight};
        box-shadow: inset 0px 0px 0px 2px ${palette.violetLight};
      }

      :disabled {
        cursor: auto;
        color: ${palette.text};
        background-color: ${palette.disabled};
        box-shadow: none;
      }

      ${text &&
      css`
        border: none;
        background-color: transparent;
        color: ${textColor || palette.text};
        font-weight: 500;
        font-size: ${fontSize || '18px'};
        :hover {
          box-shadow: none;
          color: ${textColor || palette.text};
          background-color: transparent;
        }
      `}

      ${secondary &&
      css`
        background-color: ${palette.yellow};
        color: ${palette.text};
        :hover {
          color: ${palette.yellow};
          cursor: pointer;
          background-color: ${palette.white};
          -webkit-box-shadow: inset 0px 0px 0px 2px ${palette.yellow};
          -moz-box-shadow: inset 0px 0px 0px 2px ${palette.yellow};
          box-shadow: inset 0px 0px 0px 2px ${palette.yellow};
        }
      `}
    `
  }
)
