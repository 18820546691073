import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import styled, { css } from 'styled-components'
import { IConversationStatus } from '../../../types/IConversation'
import { StateTag } from '../../atoms'

const Wrapper = styled.div`
  display: flex;
  min-width: 186px;
  height: 39px;
  .status-first {
    box-shadow: 0px 4px 28px rgba(61, 68, 81, 0.1);
  }

  //mobile / tablet horizontal
  @media screen and (max-width: 1050px) {
    min-width: fit-content;
  }
`

const DropdownButton = styled.div<{ isOpen?: boolean; squared?: boolean }>(
  ({ theme, isOpen, squared }) => {
    const { palette, shadow } = theme
    return css`
      margin-left: 11px;
      width: 37px;
      height: 37px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      background: ${palette.white};
      border: 1px solid #efefef;
      box-shadow: ${shadow};
      border-radius: 10px;
      cursor: pointer;

      > span {
        ::before {
          display: block;
          content: '';
          position: relative;
          bottom: 3px;
          width: 9.5px;
          height: 9.5px;
          border-left: 2px solid ${palette.text};
          border-top: 2px solid ${palette.text};
          transform: rotate(-135deg);

          ${isOpen &&
          css`
            transform: rotate(45deg);
            bottom: 0px;
          `}
        }
      }

      ${squared &&
      css`
        border-radius: 10px;

        > span {
          ::before {
            top: 2px;
          }
        }
      `}
    `
  }
)

const DropdownWrapper = styled.div<{ isOpen?: boolean }>(({ theme, isOpen }) => {
  const { palette, shadow } = theme
  return css`
    /* position: absolute; */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    min-width: fit-content;
    height: 305px;
    background: ${palette.white};
    box-shadow: ${shadow};
    border-radius: 10px;
    position: absolute;
    z-index: 100;

    > .top-wrapper-status {
      display: flex;
      margin-bottom: 20px;
    }

    > .marker-item {
      cursor: pointer;
    }

    > .status {
      margin-bottom: 14px;
    }

    //mobile / tablet horizontal
    @media screen and (max-width: 1050px) {
      right: 40px;
    }
  `
})

interface IStatusDropdown {
  status?: IConversationStatus
  options?: IConversationStatus[]
  handleChoose?: (id: IConversationStatus['statusId']) => void
}

export const StatusDropdown = ({ status, options, handleChoose }: IStatusDropdown) => {
  const isMobile = useMediaQuery({ query: '(max-width: 380px)' })
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const getStatusBulletColor = (id: string) =>
    options?.find((item) => item?.statusId === id)?.bulletColor

  const handleOpenClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleStatusClick = (
    id: IConversationStatus['statusId'],
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation()
    if (handleChoose) {
      handleChoose(id)
    }
    setIsDropdownOpen(false)
  }

  const filteredOptions = options?.filter((option) => option?.statusId !== status?.statusId)

  return (
    <Wrapper>
      {!isDropdownOpen && (
        <>
          <StateTag
            className="status-first"
            key={status?.statusId}
            label={status?.label || ''}
            bulletColor={getStatusBulletColor(status?.statusId || '')}
            noLabel={isMobile}
          />
          {!!options?.length && (
            <DropdownButton onClick={handleOpenClose} isOpen={isDropdownOpen}>
              <span />
            </DropdownButton>
          )}
        </>
      )}

      {!!isDropdownOpen && (
        <DropdownWrapper>
          <div className="top-wrapper-status">
            <StateTag
              className="status-first"
              key={status?.statusId}
              label={status?.label || ''}
              bulletColor={getStatusBulletColor(status?.statusId || '')}
            />
            <DropdownButton onClick={handleOpenClose} isOpen={isDropdownOpen} squared>
              <span />
            </DropdownButton>
          </div>
          {filteredOptions?.map((item) => (
            <StateTag
              onClick={(e) => handleStatusClick(item?.statusId || '', e)}
              className="status"
              key={item?.statusId}
              label={item?.label || ''}
              bulletColor={getStatusBulletColor(item?.statusId || '')}
            />
          ))}
        </DropdownWrapper>
      )}
    </Wrapper>
  )
}
