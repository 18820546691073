import { getAxios, to } from '../../utils/axios'
import { config } from '../../utils/config'
import { ENDPOINTS } from '../../utils/endpoints'

async function getUserByEmail(email: string) {
  const request = getAxios(config.API_URL, true).get(
    ENDPOINTS.USERS + `?filters[email][$eq]=${email}`
  )

  const [error, response] = await to(request)

  if (error) {
    return [error, null]
  }
  return [null, response?.data]
}

export default getUserByEmail
