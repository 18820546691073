import React, { MutableRefObject, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { TextArea } from '../TextArea/TextArea'
import { ITextAreaInput } from './ITextAreaInput'

const Wrapper = styled.div<{ margin?: string; width?: string }>(({ margin, width }) => {
  return css`
    margin: ${margin || '1.5rem 0'};
    width: ${width || '100%'};
  `
})

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 0px;
  > svg {
    display: none;
  }

  :focus-within > svg {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    margin: 0.5rem;
  }
`

const ErrorSpan = styled.span(({ theme }) => {
  const { palette } = theme
  return css`
    font-size: 0.75rem;
    color: ${palette.error};
  `
})

const InputHeader = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    color: ${palette.text};
    margin-bottom: 0.25rem;
    font-weight: 400;
    > label {
      color: ${palette.black};
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      margin-right: 1rem;
      white-space: nowrap;
    }
    > span {
      color: ${palette.black};
      font-weight: 400;
      font-size: 16px;
      opacity: 0.6;
      margin-top: 6px;
    }
  `
})
const InputWithIconWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
`
export const TextAreaInput: React.FC<ITextAreaInput> = ({
  name,
  onBlur,
  onChange,
  value,
  error,
  placeholder,
  additionalInfo,
  label,
  width,
  margin,
  disabled,
  children,
  autoComplete,
  secondary,
  height,
  onFocus
}) => {
  const input = useRef() as MutableRefObject<HTMLTextAreaElement>

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(name, event.target.value)
    }
  }

  const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    onBlur(event)
  }

  const handleFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    if (onFocus) onFocus(event)
  }

  useEffect(() => {
    if (input.current) {
      input.current.blur()
    }
  }, [input])

  return (
    <Wrapper margin={margin} width={width}>
      {!!(label || additionalInfo) && (
        <InputHeader>
          <label htmlFor={name}>{label}</label>
          <span>{additionalInfo}</span>
        </InputHeader>
      )}
      <InputWithIconWrapper>
        <InputWrapper>
          <TextArea
            height={height}
            secondary={secondary}
            autoComplete={autoComplete}
            disabled={disabled}
            error={error}
            id={name}
            name={name}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            placeholder={placeholder}
            value={value}
          />
          {children}
        </InputWrapper>
      </InputWithIconWrapper>
      {error && <ErrorSpan>{error}</ErrorSpan>}
    </Wrapper>
  )
}
