import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { CookiesProvider } from 'react-cookie'
import { store } from './redux/store'
import { CustomRoutes } from './routing/CustomRoutes'
import { routes } from './routing/routes'
import GlobalStyle from './style/GlobalStyle'
import { theme } from './style/theme'
import { AppWrapper } from './components/wrappers/AppWrapper'
import { CookiesBar } from './components/organisms'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <CookiesProvider>
          <AppWrapper>
            <CustomRoutes routes={routes} />
            <CookiesBar />
          </AppWrapper>
        </CookiesProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
)
