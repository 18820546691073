import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { RootState } from '../../../redux/store'
import { useAppDispatch, useAppSelector } from '../../../redux/storeHooks'
import { Header } from '../../atoms'
import { SignUpForm } from '../../molecules/SignUpForm/SignUpForm'
import { createUser, fetchSignUpData } from '../../../redux/slices/signInSlice'
import { INewUserBody } from '../../../api/signUp/createNewUser'

const Wrapper = styled.div(({ theme }) => {
  const { palette, shadow } = theme
  return css`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 73px 98px 63px;
    background: ${palette.white};
    box-shadow: ${shadow};
    border-radius: 20px;
    max-width: 336px;

    // smaller screen
    @media screen and (min-width: 1080px) and (max-width: 1350px) {
      padding: 63px 58px 53px;
    }
    //tablet landscape
    @media screen and (min-width: 950px) and (max-width: 1080px) {
      padding: 48px 38px 48px;
      max-width: 316px;
    }

    //mobile / tablet horizontal
    @media screen and (max-width: 950px) {
      padding: 25.1px 11.5px 25.1px;
      max-width: 100%;
    }
  `
})

const BoldedHeader = styled(Header)`
  font-weight: 800;
  margin-bottom: 34px;
  //tablet landscape
  @media screen and (min-width: 950px) and (max-width: 1080px) {
    margin-bottom: 28px;
  }
  //mobile / tablet horizontal
  @media screen and (max-width: 950px) {
    font-size: 40px;
    margin-bottom: 23px;
  }
`

export const SignUp = () => {
  const dispatch = useAppDispatch()
  const signUpData = useAppSelector((state: RootState) => state.signIn.signUpData)
  // fetching text data
  useEffect(() => {
    if (!signUpData?.data) {
      dispatch(fetchSignUpData())
    }
  }, [])

  const handleSignUp = (body: INewUserBody) => {
    dispatch(createUser(body))
  }

  return (
    <Wrapper id="sign-up">
      <BoldedHeader>{signUpData?.data?.header}</BoldedHeader>
      <SignUpForm data={signUpData?.data} handleSubmit={handleSignUp} />
    </Wrapper>
  )
}
