import { getAxios, to } from '../../utils/axios'
import { config } from '../../utils/config'
import { ENDPOINTS } from '../../utils/endpoints'

async function getMainPageContent() {
  const request = getAxios(config.API_URL).get(ENDPOINTS.MAIN_PAGE_CONTENT)

  const [error, response] = await to(request)

  if (error) {
    return [error, null]
  }
  return [null, response.data]
}

export default getMainPageContent
