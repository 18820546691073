import styled, { css } from 'styled-components'

const Button = styled.button(({ theme }) => {
  const { palette, shadow } = theme
  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: ${palette.violetLight};
    box-shadow: ${shadow};
    border: none;
    cursor: pointer;

    > span {
      ::before {
        display: block;
        position: absolute;
        content: '';
        width: 2px;
        height: 17px;
        border-radius: 2px;
        background-color: ${palette.white};
        transform: rotate(45deg);
      }
      ::after {
        display: block;
        content: '';
        width: 2px;
        height: 17px;
        border-radius: 2px;
        background-color: ${palette.white};
        transform: rotate(-45deg);
      }
    }
  `
})

interface ICloseButton {
  onClick: () => void
}

export const CloseButton = ({ onClick }: ICloseButton) => {
  return (
    <Button onClick={onClick}>
      <span />
    </Button>
  )
}
