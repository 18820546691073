import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { ITextInputPassword } from './ITextInputPassword'
import crossedEye from '../../../assets/icons/eyeCrossed.svg'
import openEye from '../../../assets/icons/eyeOpen.svg'
import { TextInput } from '../TextInput/TextInput'

type IconProps = {
  onClick?: () => void
}

const Icon = styled.img<IconProps>`
  width: 1.5rem;
  position: absolute;
  right: 10px;
  top: 20%;
  cursor: pointer;
`

export const TextInputPassword: React.FC<ITextInputPassword> = ({
  name,
  onBlur,
  onChange,
  value,
  error,
  placeholder,
  label,
  disabled,
  autoComplete,
  additionalInfo,
  margin,
  secondary
}) => {
  const input = useRef() as MutableRefObject<HTMLInputElement>
  const [passwordShown, setPasswordShown] = useState(false)

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown)
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur(event)
  }

  useEffect(() => {
    if (input.current) {
      input.current.blur()
    }
  }, [input])

  return (
    <TextInput
      secondary={secondary}
      margin={margin}
      additionalInfo={additionalInfo}
      autoComplete={autoComplete}
      disabled={disabled}
      error={error}
      label={label}
      name={name}
      onBlur={handleBlur}
      onChange={onChange}
      placeholder={placeholder}
      type={passwordShown ? 'text' : 'password'}
      value={value}>
      <Icon
        alt="preview"
        onClick={togglePasswordVisibility}
        src={passwordShown ? crossedEye : openEye}
      />
    </TextInput>
  )
}
