import { getAxios, to } from '../../utils/axios'
import { config } from '../../utils/config'
import { ENDPOINTS } from '../../utils/endpoints'

export type IFacebookLoginBody = {
  accessToken: string
}

async function facebookLogin(body: IFacebookLoginBody) {
  const request = getAxios(config.API_URL, true).get(
    `${ENDPOINTS.FACEBOOK_LOGIN}${body.accessToken}`
  )

  const [error, response] = await to(request)

  if (error) {
    return [error, null]
  }
  return [null, response.data]
}

export default facebookLogin
