import { getAxios, to } from '../../utils/axios'
import { config } from '../../utils/config'
import { ENDPOINTS } from '../../utils/endpoints'

export type IChangeCompanyPasswordBody = {
  companyId: number
  password: string
  passwordEditDate: Date
}

async function changeCompanyPassword(body: IChangeCompanyPasswordBody) {
  const request = getAxios(config.API_URL, true).put(ENDPOINTS.COMPANY + `/${body.companyId}`, {
    data: {
      password: body.password,
      passwordEditDate: body.passwordEditDate
    }
  })

  const [error, response] = await to(request)

  if (error) {
    return [error, null]
  }
  return [null, response.data]
}

export default changeCompanyPassword
