import { getAxios, to } from '../../utils/axios'
import { config } from '../../utils/config'
import { ENDPOINTS } from '../../utils/endpoints'

export type IUpdateCompanyBody = {
  id: number
  name?: string
  mainColor?: string
  secColor?: string
  password?: string
  usersPermissionsUsers?: number[] // list of  users id
  departments?: number[] //list of departments id
  tags?: number[]
}

async function updateCurrentCompany(body: IUpdateCompanyBody) {
  const params = body?.usersPermissionsUsers
    ? {
        data: {
          ...body,
          users_permissions_users: body.usersPermissionsUsers
        }
      }
    : {
        ...body
      }
  const request = getAxios(config.API_URL, true).put(ENDPOINTS.COMPANY + `/${body.id}`, params)

  const [error, response] = await to(request)

  if (error) {
    return [error, null]
  }
  return [null, response.data]
}

export default updateCurrentCompany
