import React from 'react'
import styled, { css } from 'styled-components'
import { hexRegex } from '../../../utils/regex'
import { ITag } from './ITag'

interface IWrapper {
  margin?: string
  color?: string
  backgroundColor?: string
  opacity?: string
  onClick?: () => void
}

const Wrapper = styled.div<IWrapper>(
  ({ theme, margin, color, backgroundColor, opacity, onClick }) => {
    const { palette } = theme
    return css`
      margin: ${margin};
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      width: fit-content;
      height: fit-content;
      padding: 9px;
      gap: 10px;
      color: ${color || palette.white};
      background: ${backgroundColor || palette.text};
      border-radius: 5px;
      opacity: ${opacity};
      cursor: default;
      ${onClick &&
      css`
        cursor: pointer;
      `}

      > p {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
      }
      > .close {
        cursor: pointer;
        position: relative;
        bottom: 1px;
        color: ${color || palette.white};
        font-size: 19px;
        font-weight: 300;
        line-height: 17px;
        background-color: transparent;
        border: none;
      }
    `
  }
)

export const Tag = ({
  label,
  handleDelete,
  hasDelete,
  margin,
  color,
  backgroundColor,
  opacity,
  handleClick
}: ITag) => {
  // handle incorrect color (other than hex)-> change text color from white to black to be visible on white background
  const textColor = backgroundColor
    ? hexRegex.test(backgroundColor)
      ? color
      : '#3D4451'
    : undefined
  return (
    <Wrapper
      margin={margin}
      color={textColor}
      backgroundColor={backgroundColor}
      opacity={opacity}
      onClick={handleClick}>
      <p>{label}</p>
      {hasDelete && (
        <button type="button" className="close" onClick={handleDelete}>
          &times;
        </button>
      )}
    </Wrapper>
  )
}
